import Styles from "./Service_Edit.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'

import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';


import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {IGmap, IListedService, IService, ITime, IWDays, iWizardInfo, IWorkingSlot} from "../../interfaces/interfaces";


import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import Grid from "@mui/material/Unstable_Grid2";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";


const utils = new Utils();



interface Errors {

}

//IWorkingSlot

// interface Step2 {
//     url_name: string;
//     service_type: string;
//     created_by: string;
//     name: string;
//     city: string;
//     address: string;
//     phone: string;
//     website: string;
//     map: IGmap,
// }

interface Props{
    serviceData: IService;
    setServiceData: React.Dispatch<React.SetStateAction<IService>>;
    //handleAdd: (e : React.FormEvent) => void;
}

interface ServiceSlotProps {
    slot: IListedService;
    index: number;
}

export const Step3 = ({serviceData, setServiceData} : Props) => {

    const [validateErrors, setValidateErrors] = useState<number[]>()


    const navigate = useNavigate();



    const ValidateName = async ( ) => {
    }

    const ValidateInput = async ( key: string ) => {
    }

    const slot_Add = () => {
        console.log("add");

        var newSlot : IListedService  = {
            sid: Math.random().toString(36).substr(2),
            name: "",
            time: {h: 1, m: 0},
            price: -1,
            info: ""
        }

        var new_services : IListedService[] = [ ...(serviceData.list_of_services || []), newSlot ];
        setServiceData ({
                ...serviceData,
                list_of_services : new_services
            }
        )



    }

    const slot_Del = async ( index: number ) => {
        //console.log('del ' + index)

        var new_services : IListedService[] = [ ...(serviceData.list_of_services || []) ];
        new_services.splice(index, 1)


        //console.log(new_working_hours)

        setServiceData ({
                ...serviceData,
                list_of_services : new_services
            }
        )



    }


    const slot_Upd_Time = ( index: number, dt: dayjs.Dayjs |  null, name: string ) => {

        if (dt != null) {
            var new_services : IListedService[] = [ ...(serviceData.list_of_services || []) ];

            const nm = name.split(".");

            try {
                var val;
                if (nm[1] == "h") val = dt.hour();
                if (nm[1] == "m") val = dt.minute();
                new_services[index][nm[0] as keyof IListedService][nm[1] as keyof ( ITime | string | number )] = val as never;

                setServiceData ({
                        ...serviceData,
                        list_of_services : new_services
                    }
                )
            } catch (e) {

            }
            //console.log();
            //console.log(index)
            //console.log(name)
            //console.log(dt.hour())
        }


    }

    const slot_Upd_text = ( index: number, txt: string | number | null, key: string ) => {

        var new_services : IListedService[] = [ ...(serviceData.list_of_services || []) ];

        try {

            new_services[index][key as keyof IListedService] = txt as never;

            setServiceData ({
                    ...serviceData,
                    list_of_services : new_services
                }
            )
        } catch (e) {

        }

        if (txt != null) {



            /*
            var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
            var day_num = txt.target.name;

            try {
                new_working_hours[index][key as keyof IWorkingSlot][day_num as keyof (ITime | IWDays)] = ch.target.checked as never;
                setServiceData ({
                        ...serviceData,
                        working_hours : new_working_hours
                    }
                )
            } catch (e) {

            }

            console.log(index)
            console.log(key)
            console.log(ch.target.checked)
            console.log(ch.target.name)

             */
        }
    }
    const ServiceSlotUi = ( { slot, index } : ServiceSlotProps ) => {

        var isError = validateErrors?.includes(index);
        var errorBorder = "0 px";
        if (isError) errorBorder = '1px solid #ff0000'

        var [inputName, setInputName] = useState<string>(slot.name)
        var [inputPrice, setInputPrice] = useState<number>(slot.price)
        var [inputInfo, setInputInfo] = useState<string>(slot.info)

        return (
            <Grid xs={12} spacing={0} className={Styles.block}  style={{textAlign:"left", color:'#7E7E7E', border: errorBorder}}>
                <div>
                    <div style={{textAlign:'right', lineHeight: '1px', color: '#E45F2B', margin:'-15px -3px'}}>
                        <IconButton
                            edge="end"
                            color="error"
                            onClick={()=>slot_Del(index)}
                            aria-label="close"
                        >
                            <DeleteIcon fontSize={"small"}/>
                        </IconButton>
                    </div>


                    <Grid container spacing={0} style={{paddingTop: '10px'}}>
                        <Grid  xs={12}>

                                <TextField
                                    fullWidth

                                    label="Назва"
                                    id="step3_name"
                                    placeholder={"Наприклад: консультація"}
                                    variant="outlined"
                                    value={inputName}
                                    style={{color:'#F6C445'}}
                                    size={"small"}
                                    onChange={(e)=>setInputName(utils.clearSymbol(e.target.value))}
                                    onBlur={()=>slot_Upd_text(index, inputName, "name") }
                                />

                        </Grid>

                        <Grid  xs={5.5} alignItems="flex-end">
                            {/* Left div */}
                            <div style={{paddingTop: '6px'}}>
                                Займає часу
                                <div style={{paddingTop: '4px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={5.5}>
                                            {/* Lh div */}
                                            <div>
                                                <TimePicker
                                                    views={['hours']}
                                                    format="HH"
                                                    label="год"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.time.h}:${slot.time.m}`)}
                                                    onChange={ (v) => slot_Upd_Time(index, v, "time.h")}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid  xs={1}>
                                            {/* : div */}
                                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '18px', fontSize:'16px', fontWeight:'bold' }}>
                                                <span>:</span>
                                            </div>
                                        </Grid>
                                        <Grid  xs={5.5}>
                                            {/* Rh div */}
                                            <div >
                                                <TimePicker
                                                    views={['minutes']}
                                                    format="mm"
                                                    label="хв"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.time.h}:${slot.time.m}`)}
                                                    onChange={ (v) => slot_Upd_Time(index, v, "time.m")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid  xs={1}>
                            {/* Middle div */}
                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '48px', fontSize:'16px', fontWeight:'bold' }}>
                                <span> </span>
                            </div>
                        </Grid>
                        <Grid  xs={5.5} alignItems={"flex-end"} justifyItems={"flex-start"}>
                            {/* Right div */}
                            <div >
                                &nbsp;
                                <div style={{paddingTop: '10px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={12}>
                                            {/* Lh div */}
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    label="Ціна (опційно)"
                                                    variant="outlined"
                                                    type={"number"}
                                                    size={"small"}

                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="end" className={Styles.inpPrefixHide}></InputAdornment>,
                                                        endAdornment: <InputAdornment position="start" ><span className={Styles.inpSuf}> грн</span></InputAdornment>,

                                                    }}

                                                    value={inputPrice < 0 ? "" : inputPrice}
                                                    onChange={(e)=>setInputPrice(Number(e.target.value))}
                                                    onBlur={()=>slot_Upd_text(index, inputPrice, "price") }

                                                />

                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </Grid>

                        <Grid  xs={12} style={{paddingTop: '16px', paddingBottom: '5px'}}>
                            <TextField
                                fullWidth

                                label="Опис"
                                id="step3_info"
                                placeholder={"Наприклад: доросла / сімейна / дитяча "}
                                variant="outlined"
                                value={inputInfo}
                                size={"small"}
                                onChange={(e)=>setInputInfo(utils.clearSymbol(e.target.value))}
                                onBlur={()=>slot_Upd_text(index, inputInfo, "info") }
                            />

                        </Grid>
                    </Grid>

+ кількість для групових
                </div>
            </Grid>
        );
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={Styles.module_block}>


                <div className={Styles.Panel_title}>Опції доступні при записі на обслуговування:</div>
                <Grid container spacing={0} >
                {
                    serviceData.list_of_services?.map((row: IListedService, index: number ) => (
                        <ServiceSlotUi slot={row} key={index} index={index}/>


                    ))
                }



                </Grid>
                <center>
                    <Button color="success" onClick={slot_Add}>[+] Додати</Button>
                </center>


                <center>
                    <div style={{ paddingBottom: '50px' }}></div>
                </center>


            </div>
        </LocalizationProvider>
    );
};

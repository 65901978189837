import Styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'
import React from "react";
import {IUserInfo} from "../../models/user";
import footer_my_calendar from '../../images/ui/footer_my_calendar.png'
import footer_my_service from '../../images/ui/footer_my_service.png'
import footer_my_profile from '../../images/ui/footer_my_profile.png'
import footer_my_clients from '../../images/ui/footer_my_clients.png'


interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo,
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>
    footer: React.ReactNode;
    //handleAdd: (e : React.FormEvent) => void;
}


export const Footer = ({userAuth, setUserAuth, userInfo, setUserInfo, footer} : Props) => {




    return (
        <>
        {
            footer == null ? <></> : <>
                <div className={Styles.footer}>
                    {footer}
                </div>
            </>
        }
        </>
  );
};

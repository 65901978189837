import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./BookingsUsers.module.scss";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, IconButton} from "@mui/material";
import {API} from "../../services/API";
import {FooterLinks_MainMenu} from "../footer/FooterLinks_MainMenu";
import {IBooking, IService} from "../../interfaces/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import IMG_BTN_PAUSE from "../../images/ui/service_btn_pause.png";
import IMG_BTN_PLAY from "../../images/ui/service_btn_play.png";
import IMG_BTN_STATS from "../../images/ui/service_btn_stats.png";
import IMG_BTN_SHARE from "../../images/ui/service_btn_share.png";
import IMG_BTN_CONFIG from "../../images/ui/service_btn_config.png";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface itemData { key: string; row: IBooking; }

export const BookingsUsers = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    //const [inputDomain, setInputDomain] = useState<string>("")

    const [userBookings, setUserBookings] = useState<IBooking[]>([])

    const handleBack = ( ) => {
        navigate(-1)
    };
    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
        //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
        // let req_res = await API.service_checkName(inputDomain)
    }


    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st
        }
        return {label: "", value : ""};

    }

    const getEmployeeByPhone = ( serviceData: IService, phone: string ) => {
        for ( var empl of serviceData?.employees || []) {
            if ( empl.phone == phone) return empl;
        }
        return null;
    }

    const getTaskBySid = ( serviceData: IService, sid: string ) => {
        for ( var los of serviceData?.list_of_services || []) {
            if ( los.sid == sid) return los;
        }
        return null;
    }

    // const processUsersBookings = ( data: IBooking[]) => {
    //     console.log(data)
    // }
    const fetchData = async () => {
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.bookings_byUser(token);
        setUserBookings(data.message.data)
        setIsLoading(false);
    }

    useEffect( () => {
        setIsLoading(true);

        fetchData();
    }, [])


    const DateFormatter = new Intl.DateTimeFormat('uk', {
        weekday: "long",
        day: "numeric",
        month: "long",
    });



    const Item = ( { row } : itemData ) => {
        console.log(row)

        //row.service_type
        //row.url_name
        //row._id

        //let name = row.url_name || "";
        //if (name.length > 15) name = name.slice(0, 14) + '..';
        //let service_type_obj = getServiceType(row.service_type || "")
        //let address = row.address || "Адресу не вказано"

        let formated_date = "";
        if ( row?.date )
            formated_date = DateFormatter.format(new Date( ( Date.parse(row.date + "T00:00:00" ))) )
        const tasks = row.tasks?.map( ( tsk: string) => getTaskBySid(row.service || {}, tsk)?.name );


        return  (
            <Grid className={Styles.gridRow} xs={12} container spacing={1}>
                <Grid xs={2.7} spacing={0}>
                    <div style={{height: "100%"}}>
                        <center className={Styles.col_time}>


                                {formated_date.split(",").map( (dt)=> {
                                   return (<div className={Styles.title_date}>{dt}</div>)
                                })}


                            <div className={Styles.title_name}>{utils.timeSlot2str(row.time_slot || 0)} </div>
                            <div className={Styles.title_type}>{utils.minutes2str(row.bookingTime_min || 0 )}</div>
                        </center>
                    </div>
                </Grid>

                <Grid xs={4.7} spacing={0} className={Styles.col_name}>
                    {row.service?.name}
                    <div style={{paddingBottom: '5px'}}></div>
                    { row.tasks?.map( ( tsk) => (
                        <div className={Styles.title_type}> {getTaskBySid(row.service || {}, tsk)?.name}  </div>
                    ) ) }

                </Grid>

                <Grid xs={3.6} spacing={0} className={Styles.col_addres}>
                    <div className={Styles.title_type}>
                        {getServiceType(row.service?.service_type || "").label || ""}
                        ,&nbsp;
                        {getEmployeeByPhone(row.service || {}, row.employee || "" )?.nickname}
                    </div>
                    <div className={Styles.title_type}>{row.service?.address}</div>
                    <div className={Styles.title_type}>
                        <a href={`tel: ${utils.formatPhoneNumber(row.service?.phone??"")}`}>{utils.formatPhoneNumber(row.service?.phone??"")}</a>
                    </div>

                    <div className={Styles.title_map}><a href={`https://www.google.com/maps/search/?api=1&query=${row.service?.map?.lat},${row.service?.map?.lng}`} target={"_blank"}>Відкрити карту</a></div>
                </Grid>
                <Grid xs={1} spacing={0} className={Styles.col_time}>
                    <IconButton onClick={()=> setOpen({isOpen: true, data: row})}  style={{color:'#E45F2B' , marginLeft:"-10px"}} aria-label="Відмінити запис" size="small">
                        <DeleteForeverIcon />

                    </IconButton>


                </Grid>


                {/*<Grid xs={6} className={Styles.buttons_grp}>*/}
                {/*    <IconButton onClick={handleBack}  color="secondary" aria-label="Зупинити" size="small">*/}
                {/*        <img src={IMG_BTN_PAUSE || IMG_BTN_PLAY} alt="Зупинити" title="Зупинити" />*/}
                {/*    </IconButton>*/}
                {/*    <IconButton onClick={handleBack}  color="secondary" aria-label="Статистика" size="small">*/}
                {/*        <img src={IMG_BTN_STATS} alt="Статистика" title="Статистика" />*/}
                {/*    </IconButton>*/}
                {/*    <IconButton onClick={()=>navigate(`/services/share/${row._id}`)}  color="secondary" aria-label="Інфо для клієнтів" size="small">*/}
                {/*        <img src={IMG_BTN_SHARE} alt="Інфо для клієнтів" title="Інфо для клієнтів" />*/}
                {/*    </IconButton>*/}



                {/*</Grid>*/}
            </Grid>

        )
    }



    const [open, setOpen] = useState<{isOpen: boolean, data: IBooking}>({isOpen: false, data: {}});
    const DialogModal = () => {
        

        const handleBookingCancel = async (booking_id: string) => {
            //bookings_byUserCancel
            setIsLoading(true);
            let token = `${userInfo.authToken};;${userInfo.authDate}`;
            let data  = await API.bookings_byUserCancel(booking_id, token);

            setIsLoading(false);
            if ( data.error > 0 ) {
                setGlobalMessage({type: "error", message: data.message} )
            } else {
                // BOOKING is canceled
                setOpen({isOpen: false, data: {}});
                setIsLoading(true);
                fetchData();

            }

            //console.log('=-=-=-=-=-')
            //console.log(data)
            //setIsLoading(false);
            return false;
        };

        const handleClose = () => {
            setOpen({isOpen: false, data: {}});
        };


        let formated_date = "";
        if ( open.data?.date )
            formated_date = DateFormatter.format(new Date( ( Date.parse(open.data?.date + "T00:00:00" ))) )
        const tasks = open.data.tasks?.map( ( tsk: string) => getTaskBySid(open.data.service || {}, tsk)?.name );


        return (
            <Dialog
                open={open.isOpen}
                onClose={handleClose}

            >
                <DialogTitle id="alert-dialog-title" color={"error"}>
                    {"Бажаєте скасувати Запис?"}
                </DialogTitle>
                <DialogContent>

                    <div style={{ color: "#f44336"}}>
                        Прошу підтвердити, що бажаєте відмінити свій запис:
                    </div>
                    <div style={{paddingTop:"15px", paddingBottom: "0px", textAlign:'center', color:'#F6C445'}}>
                        <strong >
                            {getServiceType(open.data.service?.service_type || "").label || ""},&nbsp;
                            {open.data.service?.name}
                        </strong>
                    </div>
                    <div style={{paddingTop:"0px", paddingBottom: "0px", textAlign:'center', color:'#72FA93'}}>
                        <strong >
                            {utils.timeSlot2str(open.data.time_slot || 0)}
                        </strong>
                    </div>
                    <div style={{paddingTop:"0px", paddingBottom: "0px", textAlign:'center', color:'#72FA93'}}>
                        <strong >
                            {formated_date }
                        </strong>
                    </div>
                    <div style={{paddingTop:"0px", paddingBottom: "15px", textAlign:'center', color:'#7A7A7A'}}>
                        ( тривалість {utils.minutes2str(open.data.bookingTime_min || 0 )} )
                    </div>

                    <div style={{color:'#7A7A7A'}} >Працівник: <span style={{color:'#F6C445'}}>{getEmployeeByPhone(open.data.service || {}, open.data.employee || "" )?.nickname} </span></div>
                    <div style={{color:'#7A7A7A'}}>Послуги: &nbsp;
                        <span style={{color:'#F6C445'}}>
                            {tasks?.join(", ")}
                        </span>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleBookingCancel(open.data._id || "" )} variant={"contained"} color={"error"} startIcon={<DeleteForeverIcon/>}> Відмінити запис</Button>
                    <Button onClick={handleClose} autoFocus color={"success"}>
                        Закрити
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }


    return (
        <div>
            <center><h1>Мої записи</h1></center>
            <DialogModal/>
            <Grid container spacing={1}>
                {
                   userBookings.map((row) => (
                        <Item key={(row._id || "" ).toString()} row={row}/>

                    ))
                }

            </Grid>
        </div>
    );
}


import React, {useState, useEffect} from 'react';
import Styles from "./Login.module.scss";
//import { IoCalendarSharp } from 'react-icons/io5';
import {IUserInfo} from "../../models/user";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {API} from "../../services/API";
import {AlertColor, FormControl, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IMaskInput } from 'react-imask';
import Button from "@mui/material/Button";
import LoginIcon from '@mui/icons-material/Login';
const utils = new Utils();




interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    //authFrame: Window | null;
    //handleAdd: (e : React.FormEvent) => void;
}



interface inputPhoneProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const inputPhoneMask = React.forwardRef<HTMLInputElement, inputPhoneProps>(
    function inputPhoneMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[0]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export const Login = ({userAuth, setUserAuth, userInfo, setUserInfo, setGlobalMessage }:Props) => {

    const [inputPhone, setInputPhone] = useState<string>("")
    const [inputCode, setInputCode] = useState<string>("")
    const [isPhone, setIsPhone] = useState<boolean>(false)

    const [errorValidPhone, setErrorValidPhone] = useState<string>("")

    const [addEmplPhone, setAddEmplPhone] = React.useState({
        phoneMask: '',
        phone: '',
        numberformat: '1320',
        countyCode: '38'

    });

    useEffect( () => {

        setInputPhone( userInfo.phone || "" );


        setAddEmplPhone({
            ...addEmplPhone,
            phoneMask: userInfo.phone,
            phone: userInfo.phone
        });

        //setInputPhone( localStorage.getItem('inputPhone') || "");

    }, [])

    const auth_btn = async () => {

        if ( !ValidatePhone() ) {
            setGlobalMessage({type: "error", message: 'не вірний номер'} )
            return;
        }

        //addEmplPhone.phone

        localStorage.setItem('inputPhone', addEmplPhone.phone)


        let req_res = await API.auth(addEmplPhone.phone)
        if (req_res) {
            if (req_res.message.error ) {
                //alert(req_res.message.message);
                setGlobalMessage({type: "error", message: req_res.message.message} )
            } else {
                setIsPhone(true);
            }
        }


        /*
        const pattern = /^\d{10}$/;
        if ( !pattern.test(inputPhone) ) {
            //alert('не вірний номер');
            setGlobalMessage({type: "error", message: 'не вірний номер'} )
            return;
        }

        //authFrame?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'inputPhone', method: 'set', data: inputPhone }), '*');

        localStorage.setItem('inputPhone', inputPhone)
        //console.log('click', inputPhone)

        let req_res = await API.auth(inputPhone)
        if (req_res) {
            if (req_res.message.error ) {
                //alert(req_res.message.message);
                setGlobalMessage({type: "error", message: req_res.message.message} )
            } else {
                setIsPhone(true);
            }
        }*/

    }


    const ValidatePhone = ( ) => {
        //addEmplPhone
        const a10digitsPhonePattern = /^\d{10}$/;

        if ( ! a10digitsPhonePattern.test (addEmplPhone.phone) ) {
            setErrorValidPhone("Не вірний номер телефону");
            return false;
        }


        //console.log(addEmplPhone)
        setErrorValidPhone("");
        return true;

    }



    const handleAddEmplPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddEmplPhone({
            ...addEmplPhone,
            [event.target.name]: event.target.value,
            phone: event.target.value.replace(/\D/g, '')
        });

        //console.log(addEmplPhone)
    };

    const auth_code_btn = async () => {
        const pattern = /^\d{5}$/;
        if ( !pattern.test(inputCode) ) {

            setGlobalMessage({type: "error", message: 'не вірний код'} )
            return;
        }

        //localStorage.setItem('inputPhone', inputPhone)

        //console.log('click', inputPhone)
        /*
        let req = {
            phone: `38${inputPhone}`,
            sms_code: inputCode //add UA code
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/authcheck`, req, "");
        */


        let req_res = await API.authcheck(inputPhone, inputCode)

        if (req_res.message.authorized){
            setUserAuth(true)
            //console.log(req_res)
            setUserInfo( {authDate : req_res.message.authDate, authToken: req_res.message.authToken, phone: `38${inputPhone}`, name: "", city: "", info: ""} );
            localStorage.setItem('authToken', req_res.message.authToken)
            localStorage.setItem('authDate', req_res.message.authDate)
            //authFrame?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authToken', method: 'set', data: req_res.message.authToken }), '*');
            //authFrame?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authDate', method: 'set', data: req_res.message.authDate }), '*');
        } else {
            setUserAuth(false)
            localStorage.setItem('authToken', "")
            localStorage.setItem('authDate', Date.now().toString())
            //authFrame?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authToken', method: 'set', data: "" }), '*');
            //authFrame?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authDate', method: 'set', data: Date.now().toString() }), '*');
            setUserInfo( {authDate : Date.now(), authToken: "", phone: `38${inputPhone}`, name: "", city: "", info: ""} );

        }

    }

    //{ ( userAuth && userAuth['authToken'])  <div>Авторизовано</div>: <div>НІ</div> }
    return (
        <div className={Styles.loginDiv}>
            { ! isPhone ?
            <div>
                <p style={{color:"#E45F2B", textAlign:"center",paddingTop:'10px'}}>Авторизуйтеся для продовження!</p>
                <FormControl
                    variant="standard"
                    fullWidth
                    error={errorValidPhone.length > 0}
                    >
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Ваш мобільний телефон, прийде СМС з кодом
                    </InputLabel>
                    <Input
                        sx={{fontSize: '20px', color:"#F6C445"}}
                        id="input-phone"
                        onKeyUp={(e)=>{ if (e.key === "Enter") auth_btn();  }}
                        value={addEmplPhone.phoneMask}
                        onChange={handleAddEmplPhoneChange}
                        name="phoneMask"
                        placeholder={"(063) 111-1234"}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle fontSize={"large"}/>
                                <span  style={{color: '#F6C445', fontSize: '20px', padding: '0px 0px 0px 10px'}}>+38</span>
                            </InputAdornment>
                        }
                        inputComponent={inputPhoneMask as any}
                        endAdornment={
                            <Button color={"success"} variant={"contained"}  startIcon={<LoginIcon/>} onClick={auth_btn}>  ВХІД </Button>
                        }
                    />
                    {!errorValidPhone ? "" : <div className={Styles.validation_err}><span>{errorValidPhone}</span> </div>}
                </FormControl>
            </div>
                    :
            <div>
                <p>Код доступу вислано в СМС</p>
                <div className={Styles.input_group}>
                    <input
                        className={Styles.inputCode}
                        type="number"
                        placeholder="код з СМС"
                        onKeyUp={(e)=>{ if (e.key === "Enter") auth_code_btn();  }}
                        onChange={(e)=>setInputCode(e.target.value)}
                    />
                    <button onClick={auth_code_btn}>
                        Підтвердити
                    </button>

                </div>

                <a onClick={()=>{setIsPhone(false)}}>Спробувати ще раз</a>

            </div>

            }
            <div style={{paddingBottom:'20px'}}></div>
        </div>
    );
};
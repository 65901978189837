import React from "react";
import CONFIG from "../config";
import {Utils} from "../helpers/utils";
import {IGmap, IService} from "../interfaces/interfaces";

interface Props{

}

const utils = new Utils();
//export class API {
export const API = {

    auth : async ( inputPhone: string )=> {

    //const auth = async ( inputPhone: string )=> {
        let req = {
            phone: `38${inputPhone}` //add UA code
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/auth`, req, "");
        //if (req_res)
        return req_res

    },

    authcheck: async (inputPhone: string, inputCode: string) =>{
        let req = {
            phone: `38${inputPhone}`,
            sms_code: inputCode //add UA code
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/authcheck`, req, "");

        return req_res
    },

    authrecheck: async (token: string) =>{
        let req = {
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/authrecheck`, req, token);

        return req_res
    },

    service_checkName: async (inputDomain: string) => {
        let req = {url_name: inputDomain};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/checkName`, req, "");
        return req_res
    },

    service_add: async (inputDomain: string, inputServiceType: string, token: string) =>{
        let req = {
            url_name: inputDomain,
            service_type: inputServiceType
        }

       let req_res = await utils.requestPost(`${CONFIG.API}/services/add`, req, token);
        return req_res

    },

    service_list: async (token: string) => {
        let req = {};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/list`, req, token);
        return req_res
    },

    service_listPublicDemo: async () => {
        let req = {};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/listPublicDemo`, req, "");
        return req_res
    },

    service_edit_getOne: async (token: string, id: string) => {
        let req = {id: id};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/getOne`, req, token);
        return req_res
    },

    service_edit_update: async (token: string, service: IService) => {
        let req = {service: service};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/update`, req, token);
        return req_res
    },
    service_check_domain: async ( domain: string) => {
        let req = {domain: domain};
        let req_res = await utils.requestPost(`${CONFIG.API}/services/checkDomain`, req, "");
        return req_res
    },


    gmap_address_by_coords: async ( latlon: IGmap)=> {

        let req_res = await utils.requestGetExt(`${CONFIG.GMAP_API}?latlng=${latlon.lat},${latlon.lng}&key=${CONFIG.GMAP_KEY}`);
        return req_res

    },


    gmap_address_by_address: async ( address: string)=> {

        let req_res = await utils.requestGetExt(`${CONFIG.GMAP_API}?address=${address}&key=${CONFIG.GMAP_KEY}`);
        return req_res

    },

    booking_getBookingInfo:  async ( service_id: string, inputEmployee: string, inputTasks: {[p: string]: boolean}, dateCalendar:  Date | undefined, token: string)=> {
        let req = {service_id, inputEmployee, inputTasks, dateCalendar};
        let req_res = await utils.requestPost(`${CONFIG.API}/booking/getBookingInfo`, req, token);
        return req_res

    },

    booking_bookSlot:  async ( service_id: string, inputEmployee: string, inputTasks: {[p: string]: boolean}, dateCalendar:  Date | undefined, selectedTimeSlot: number, token: string) => {
        let req = {service_id, inputEmployee, inputTasks, dateCalendar, selectedTimeSlot};
        let req_res = await utils.requestPost(`${CONFIG.API}/booking/bookTime`, req, token);
        return req_res
    },

    bookings_byUser: async (token: string )=> {
        let req = {};
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/userBookings`, req, token);
        return req_res
    },

    bookings_byUserCancel: async (booking_id: string, token: string )=> {
        let req = { booking_id };
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/userBookingsCancel`, req, token);
        return req_res
    },
    bookings_byEmployeeCancel: async (booking_id: string, token: string )=> {
        let req = { booking_id };
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/employeeBookingsCancel`, req, token);
        return req_res
    },

    bookings_byEmployee: async (service_id: string, token: string )=> {
        let req = {};
        if (service_id.length > 3) {
            req = { service_id };
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/employeeBookings`, req, token);
        return req_res
    },
    bookings_byService: async (service_id: string, token: string )=> {
        let req = {};
        if (service_id.length > 3) {
            req = { service_id };
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/serviceBookings`, req, token);
        return req_res
    },

    bookings_checkEmployee: async (service_id: string, phone: string, token: string )=> {
        let req = {service_id, phone};
        let req_res = await utils.requestPost(`${CONFIG.API}/bookings/checkEmployeeBookings`, req, token);
        return req_res
    },


   uploads_avatar: async (formData :  FormData, token: string )=> {
        let req_res = await utils.requestPostForm(`${CONFIG.API}/upload/avatar`, formData, token);
        return req_res
    },
    employment_addEmployee: async (phone: string, service_id: string, token: string) => {
        let req = { phone, service_id };


        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/addEmployee`, req, token);
        return req_res
    },
    employment_checkEmployment: async (employment_id: string, token: string) => {
        let req = { employment_id };

        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/checkEmployment`, req, token);
        return req_res
    },
    employment_remove: async (employment_id: string, token: string) => {
        let req = { employment_id };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/remove`, req, token);
        return req_res
    },
    employment_getInvites: async (token: string) => {
        let req = {  };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/getInvites`, req, token);
        return req_res
    },
    employment_approve: async (employment_id: string, token: string) => {
        let req = { employment_id };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/approve`, req, token);
        return req_res
    },
    employment_reject: async (employment_id: string, token: string) => {
        let req = { employment_id };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/reject`, req, token);
        return req_res
    },
    employment_getEmployments: async (token: string) => {
        let req = {  };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/employment/getEmployments`, req, token);
        return req_res
    },
    profile_updateName: async (name: string, token: string) => {
        let req = { name };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/profile/updateName`, req, token);
        return req_res
    },
    profile_updateInfo: async (info: string, token: string) => {
        let req = { info };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/profile/updateInfo`, req, token);
        return req_res
    },
    profile_updateCity: async (city: string, token: string) => {
        let req = { city };
        let req_res = await utils.requestPostForm(`${CONFIG.API}/profile/updateCity`, req, token);
        return req_res
    }

    //newf: async () =>{
        //return req_res

    //},


}



import {IService} from "../../interfaces/interfaces";
import React from "react";
import QRCode from 'react-qr-code';
import CONFIG from "../../config";
import Styles from "./Service_Share.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {Utils} from "../../helpers/utils";

type PropsPrint = {
    serviceData?: IService,
    SERVICE_URL?: string;
};

const utils = new Utils();

export class PrintComponent extends React.PureComponent<PropsPrint> {
    constructor(props: PropsPrint) {
        super(props);
    }
    private canvasEl!: HTMLCanvasElement;

    public componentDidMount() {
    }

    private setRef = (ref: HTMLCanvasElement) => this.canvasEl = ref;


    public render() {
        const {
            serviceData,
            SERVICE_URL,
        } = this.props;

        const SERVICE_URL2 = `${serviceData?.url_name}.${CONFIG.MAIN_DOMAIN}`


        const getServiceType = ( service_type : string) => {
            for (var st of CONFIG.SERVICE_TYPES) {
                if (st.value == service_type)    return st
            }
            return {label: "", value : ""};

        }

        let ki: number = 0;

        return (
            <div className={Styles.PrintPage}>
                <style type="text/css" media="print">{"@page { size: landscape; }"}</style>

                <Grid container spacing={0.2} >
                    <Grid  xs={12} className={Styles.Print_ServiceType} >{getServiceType(serviceData?.service_type || "").label}</Grid>
                    <Grid  xs={12} className={Styles.Print_ServiceName} >{serviceData?.name}</Grid>


                    <Grid  xs={5} className={Styles.Print_QR}>
                        <QRCode
                            //value={`https://${serviceData.url_name}.${CONFIG.MAIN_DOMAIN}`}
                            value={SERVICE_URL || ""}
                            size={350}
                            style={{ height: "auto" }}
                            viewBox={`0 0 256 256`}
                            bgColor={"#fff"}
                            fgColor={"#000"}
                        />
                    </Grid>
                    <Grid  xs={7}>
                        <ul className={Styles.Print_ServiceServices}>
                            { serviceData?.list_of_services?.map( (row) => (
                                <li key={ki++}>
                                    {row.name}
                                </li>
                            ) )}

                        </ul>
                    </Grid>

                    <Grid  xs={12} className={Styles.Print_ServiceURLLabel} >Онлайн запис</Grid>
                    <Grid  xs={12} className={Styles.Print_ServiceURL} >{SERVICE_URL2 ?? ''}</Grid>

                    <Grid  xs={4} className={Styles.Print_ContactInfo} >
                        {serviceData?.website ? <>Веб: <span className={Styles.label}>{serviceData?.website}</span> </> : <></>}
                    </Grid>
                    <Grid  xs={4} className={Styles.Print_ContactInfo} >
                        { serviceData?.address ? <>Адреса: <span className={Styles.label}>{serviceData?.address}</span> </>: <></> }
                    </Grid>
                    <Grid  xs={4} className={Styles.Print_ContactInfo} >
                        { serviceData?.phone ? <>тел.: <span className={Styles.label}>{utils.formatPhoneNumber(serviceData?.phone || "")}</span> </>: <></> }

                    </Grid>


                </Grid>






            </div>
        );
    }
}
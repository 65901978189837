import React, {FunctionComponent, useEffect, useRef, useState} from "react";

import Styles from "./Service_Share.module.scss";
import {useNavigate, useParams} from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';

// import EastIcon from '@mui/icons-material/East';
import QRCode from 'react-qr-code';
import ReactToPrint, {useReactToPrint} from "react-to-print";

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, Divider, IconButton, InputBase, Paper} from "@mui/material";
import {API} from "../../services/API";
import {IService} from "../../interfaces/interfaces";

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {PrintComponent} from "./PrintComponent";
import Grid from "@mui/material/Unstable_Grid2";


const utils = new Utils();





interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    //handleAdd: (e : React.FormEvent) => void;
}

export const Service_Share = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    //const [inputDomain, setInputDomain] = useState<string>("")
    const [serviceData, setServiceData] = useState<IService>( {} );
    const [doneLoading, setDoneLoading] = useState<boolean>(false);
    const params = useParams(); // access url params

    const handleBack = ( ) => {
        navigate(-1)
    };


    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
        //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
        // let req_res = await API.service_checkName(inputDomain)
    }

    const updateService = (update : object) => {
        setServiceData ( {
            ...serviceData,
            ...update,
        })
    }

    useEffect(  () => {

        //console.log(); // 👉️ {userId: '4200'}
        setFooter(null)

        const fetchData = async () => {
            let id = (params.id || "").toString();

            if (!id) {
                setGlobalMessage({type: "error", message:"Error: no ID"} )
                return;
            }

            let token = `${userInfo.authToken};;${userInfo.authDate}`;
            let data  = await API.service_edit_getOne(token, id);
            if (data.message.error > 0) {
                if (data.message.error == 401) {
                    // TODO :remove comment
                    Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
                }
                setGlobalMessage({type: "error", message:data.message.message} )
            } else {
                updateService(data.message.data)
                setDoneLoading (true)
            }
            setIsLoading(false);
        }

        setIsLoading(true);
        fetchData();


    }, [])






    const SERVICE_URL = `https://${serviceData.url_name}.${CONFIG.MAIN_DOMAIN}`
    //const SERVICE_URL = `https://${CONFIG.MAIN_DOMAIN}/s/${serviceData.url_name}`

    const componentRef = useRef<HTMLDivElement>(null)


    return (
        <div>
            <IconButton onClick={handleBack}  color="secondary" aria-label="add an alarm">
                <img src={IMG_BTN_BACK} alt="Назад" title="Назад" />
            </IconButton>



            <span className={Styles.title} > Як записатися Вашим клієнтам? </span>

            <div style={{ paddingBottom: '20px' }}></div>

            <p style={{color:'#7A7A7A'}}>Щоб Ваші клієнти змогли записатися на Ваші послги - запропонуйте їм скористатися цим QR-кодом або URL-посиланням</p>

            <div style={{ paddingBottom: '30px' }}></div>
            { doneLoading ?
                <div>
                    <strong>QR-КОД</strong>
                    <center>
                        <QRCode
                            //value={`https://${serviceData.url_name}.${CONFIG.MAIN_DOMAIN}`}
                            value={SERVICE_URL}
                            size={256}
                            style={{ height: "auto", maxWidth: "60%", width: "60%" }}
                            viewBox={`0 0 256 256`}
                            bgColor={"#000"}
                            fgColor={"#A0E548"}

                        />
                    </center>

                    <div style={{ paddingBottom: '20px' }}></div>

                    <strong>Веб-сторінка:</strong>



                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                    >

                        <InputBase
                            fullWidth
                            sx={{ ml: 1, flex: 1, color:"#F6C445", fontSize:"20px", fontWeight:"bold"}}
                            placeholder="URL лінк на Ваші послуги"
                            inputProps={{ 'aria-label': 'URL лінк на Ваші послуги', "readOnly":true  }}
                            value={SERVICE_URL}
                        />

                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton onClick={() => {navigator.clipboard.writeText(SERVICE_URL)}} color="success" sx={{ p: '10px' }} aria-label="directions">
                            <ContentCopyOutlinedIcon/>
                        </IconButton>
                    </Paper>

                    <div style={{ paddingBottom: '20px' }}></div>







                    <Grid container spacing={0.2} >
                        <Grid  xs={6} >
                            {/*Printing */}
                            <div>

                                <ReactToPrint
                                    trigger={() => <Button  color="success"  variant={"contained"} >Надрукувати або PDF</Button>}
                                    content={() => componentRef.current}
                                />
                                <div style={{  overflow: "hidden", height: 0 }}>
                                    <div ref={componentRef} >
                                        <PrintComponent SERVICE_URL={SERVICE_URL} serviceData={serviceData} />
                                    </div>
                                </div>

                            </div>
                        </Grid>

                        <Grid  xs={6} >
                            <Button color="warning"  variant={"contained"} disabled >Поділитися</Button>
                        </Grid>
                    </Grid>





                </div>
                :<div>Завантаження...</div>
            }

        </div>
    );
}
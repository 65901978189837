import Styles from "./Service_Edit.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'

import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'


import {

    Checkbox,
    FormControl, FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField, Tooltip
} from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import {IGmap, IService, ITime, IWDays, iWizardInfo, IWorkingSlot} from "../../interfaces/interfaces";


import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const utils = new Utils();



interface Errors {

}

//IWorkingSlot

// interface Step2 {
//     url_name: string;
//     service_type: string;
//     created_by: string;
//     name: string;
//     city: string;
//     address: string;
//     phone: string;
//     website: string;
//     map: IGmap,
// }

interface Props{
    serviceData: IService;
    setServiceData: React.Dispatch<React.SetStateAction<IService>>;
    //handleAdd: (e : React.FormEvent) => void;
}

interface TimeSlotProps {
    slot: IWorkingSlot;
    index: number;
}

export const Step2 = ({serviceData, setServiceData} : Props) => {

/*
    const [inputName, setInputName] = useState<string>(serviceData.name || "")

    const [inputCity, setInputCity] = useState<string>(serviceData.city || "")
    const [inputAddress, setInputAddress] = useState<string>(serviceData.address || "")
    const [inputPhone, setInputPhone] = useState<string>(serviceData.phone || "")
    const [inputWebSite, setInputWebSite] = useState<string>(serviceData.website || "")
    const [mapCenter, setMapCenter] = useState<IGmap>(serviceData.map || {lat: 49.83134102148687, lng: 24.07874763017847})
    const [mapMarker, setMapMarker] = useState<IGmap>(serviceData.map || {lat: -1, lng: -1})
    const [mapInfo, setMapInfo] = useState<{[key: string]:string} >({})


    const [errorValid1, setErrorValid1] = useState<string>("")
    const [errorValid2, setErrorValid2] = useState<string>("")
    const [errorValid3, setErrorValid3] = useState<string>("")
    const [errorValid4, setErrorValid4] = useState<string>("")
    const [errorValid5, setErrorValid5] = useState<string>("")
    const [errorValid6, setErrorValid6] = useState<string>("")
*/
    //const [goodValid1, setGoodValid1] = useState<string>("")

    const [validateErrors, setValidateErrors] = useState<number[]>()


    const navigate = useNavigate();



    const ValidateName = async ( ) => {
    }

    const ValidateTimeRanges =  ( ) => {

        const ranges :  IWorkingSlot[] = [ ...(serviceData.working_hours || [])];
        var overlappingIndices : number[] = [];
        for ( var i in ranges) {
            for (var j in ranges) {
                if ( i != j) {

                    let r1_start = ranges[i].start.h * 60 +  ranges[i].start.m;
                    let r1_end =  ranges[i].end.h * 60 + ranges[i].end.m;

                    let r2_start = ranges[j].start.h * 60 + ranges[j].start.m;
                    let r2_end =  ranges[j].end.h * 60 + ranges[j].end.m;

                    if ( (r1_start < r2_start && r2_start < r1_end ) ||  (r1_start < r2_end && r2_end < r1_end ) || (r1_start == r2_start)) {
                        // check if wdays are same
                        for ( var k of Object.keys(ranges[i].wdays) ) {
                            if (ranges[i].wdays[k as keyof unknown] && ranges[i].wdays[k as keyof unknown] == ranges[j].wdays[k as keyof unknown] ) {
                                overlappingIndices.push(Number(j))
                                break;
                            }
                        }
                    }
                }
            }
        }
        setValidateErrors(overlappingIndices)
        return overlappingIndices;
    }
    const timeSlot_Add = () => {
        console.log("add");

        var newSlot : IWorkingSlot  = {
            start: {h: 9, m: 0},
            end: {h: 18, m: 0},
            wdays:{1: true, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false},
            competences:[]
        }

        var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []), newSlot ];
        setServiceData ({
                ...serviceData,
                working_hours : new_working_hours
            }
        )

       ValidateTimeRanges()

    }

    const timeSlot_Del = async ( index: number ) => {
        //console.log('del ' + index)

        var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
        new_working_hours.splice(index, 1)


        //console.log(new_working_hours)

        setServiceData ({
                ...serviceData,
                working_hours : new_working_hours
            }
        )

        //ValidateTimeRanges();

    }


    const timeSlot_Upd = ( index: number, dt: dayjs.Dayjs |  null, name: string ) => {
        if (dt != null) {
            var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];

            const nm = name.split(".");

            try {
                var val;
                if (nm[1] == "h") val = dt.hour();
                if (nm[1] == "m") val = dt.minute();
                new_working_hours[index][nm[0] as keyof IWorkingSlot][nm[1] as keyof (ITime | IWDays)] = val as never;

                setServiceData ({
                        ...serviceData,
                        working_hours : new_working_hours
                    }
                )
            } catch (e) {

            }
            //console.log();
            //console.log(index)
            //console.log(name)
            //console.log(dt.hour())
        }
    }
    const timeSlot_UpdCB = ( index: number, ch: React.ChangeEvent<HTMLInputElement> | null, key: string ) => {
        if (ch != null) {

            var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
            var day_num = ch.target.name;

            try {
                new_working_hours[index][key as keyof IWorkingSlot][day_num as keyof (ITime | IWDays)] = ch.target.checked as never;
                setServiceData ({
                        ...serviceData,
                        working_hours : new_working_hours
                    }
                )
            } catch (e) {

            }
            /*
            console.log(index)
            console.log(key)
            console.log(ch.target.checked)
            console.log(ch.target.name)

             */
        }
    }

    const startEndDate_Upd = ( date: number, key: string ) => {

        //var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];

        //console.log(date)
        //return;

        try {
            setServiceData ({
                    ...serviceData,
                    [key as keyof IService]: date
                }
            )
        } catch (e) {
        }

            /*
            if (ch != null) {

                var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
                var day_num = ch.target.name;

                try {
                    new_working_hours[index][key as keyof IWorkingSlot][day_num as keyof (ITime | IWDays)] = ch.target.checked as never;
                    setServiceData ({
                            ...serviceData,
                            working_hours : new_working_hours
                        }
                    )
                } catch (e) {

                }
                /*
                console.log(index)
                console.log(key)
                console.log(ch.target.checked)
                console.log(ch.target.name)


            }

             */
    }

    useEffect(  () => {
        ValidateTimeRanges()
    }, [serviceData])

    const TimeSlotUi = ( { slot, index } : TimeSlotProps ) => {

        var isError = validateErrors?.includes(index);
        var errorBorder = "0 px";
        if (isError) errorBorder = '1px solid #ff0000'

        return (
            <Grid xs={12} spacing={0} className={Styles.block}  style={{textAlign:"left", color:'#7E7E7E', border: errorBorder}}>
                <div>
                    <div style={{textAlign:'right', lineHeight: '1px', color: '#E45F2B', margin:'-15px -3px'}}>
                        <IconButton
                            edge="end"
                            color="error"
                            onClick={()=>timeSlot_Del(index)}
                            aria-label="close"
                        >
                            <DeleteIcon fontSize={"small"}/>
                        </IconButton>
                    </div>


                    <Grid container spacing={0} >
                        <Grid  xs={5.5}>
                            {/* Left div */}
                            <div>
                                Відкривається
                                <div style={{paddingTop: '10px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={5.5}>
                                            {/* Lh div */}
                                            <div>
                                                <TimePicker
                                                    views={['hours']}
                                                    format="HH"
                                                    label="год"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.start.h}:${slot.start.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(index, v, "start.h")}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid  xs={1}>
                                            {/* : div */}
                                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '11px', fontSize:'16px', fontWeight:'bold' }}>
                                                <span>:</span>
                                            </div>
                                        </Grid>
                                        <Grid  xs={5.5}>
                                            {/* Rh div */}
                                            <div >
                                                <TimePicker
                                                    views={['minutes']}
                                                    format="mm"
                                                    label="хв"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.start.h}:${slot.start.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(index, v, "start.m")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid  xs={1}>
                            {/* Middle div */}
                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '40px', fontSize:'16px', fontWeight:'bold' }}>
                                <span>-</span>
                            </div>
                        </Grid>
                        <Grid  xs={5.5}>
                            {/* Right div */}
                            <div >
                                Закривається
                                <div style={{paddingTop: '10px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={5.5}>
                                            {/* Lh div */}
                                            <div>
                                                <TimePicker
                                                    views={['hours']}
                                                    format="HH"
                                                    label="год"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.end.h}:${slot.end.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(index, v, "end.h")}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid  xs={1}>
                                            {/* : div */}
                                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '11px', fontSize:'16px', fontWeight:'bold' }}>
                                                <span>:</span>
                                            </div>
                                        </Grid>
                                        <Grid  xs={5.5}>
                                            {/* Rh div */}
                                            <div >
                                                <TimePicker
                                                    views={['minutes']}
                                                    format="mm"
                                                    label="хв"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.end.h}:${slot.end.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(index, v, "end.m")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{marginBottom:'-8px', paddingTop:'5px'}}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["1"]} onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")}  color="success" name="1" size={"small"} style={{paddingRight:'0px',}}/>
                            }
                            label="пн"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["2"]}  onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")} color="success" name="2" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="вт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["3"]}  onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")} color="success" name="3" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="ср"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["4"]} onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")}  color="success" name="4" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="чт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["5"]} onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")}  color="success" name="5" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="пт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["6"]} onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")}  color="secondary" name="6" size={"small"} style={{paddingRight:'0px', paddingLeft:'3px'}}/>
                            }
                            label="сб"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["7"]} onChange={ (v) => timeSlot_UpdCB(index, v, "wdays")}  color="secondary" name="7" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="нд"
                        />
                    </div>
                    { isError ?
                        <div className={Styles.validation_err}><span>Часові відрізки не можуть накладатися</span> </div>
                        :<></>
                    }
                </div>
            </Grid>
        );
    }


    return (

            <div className={Styles.module_block}>


                <div className={Styles.block}>
                    <div className={Styles.Panel_title}>Коли дозволити запис <Tooltip title="Якщо не вказувати буде доступне від вже і без обмежень">
                        <IconButton>
                            <InfoTwoToneIcon fontSize={"small"}/>
                        </IconButton>
                    </Tooltip></div>
                    <div style={{ paddingBottom: '10px' }}></div>
                    <Grid container spacing={1} >
                        <Grid  xs={6}>
                            <DatePicker
                                label="від дати"
                                format="D-M-YYYY"
                                className={Styles.timePicker}
                                value={serviceData.startDate ? dayjs.unix(serviceData.startDate) : null}
                                onChange={ (v) => startEndDate_Upd (v?.unix() || 0, "startDate")}
                            />
                        </Grid>
                        <Grid  xs={6}>
                            <DatePicker
                                label="до дати"
                                format="D-M-YYYY"
                                className={Styles.timePicker}
                                value={serviceData.endDate ? dayjs.unix(serviceData.endDate) : null}
                                onChange={ (v) => startEndDate_Upd (v?.unix() || 0, "endDate")}
                            />
                        </Grid>
                    </Grid>

                </div>

                {/*<div className={Styles.Panel_title}>Робочі дні та години підприємства</div>
                {
                    serviceData.working_hours?.map((row: IWorkingSlot, index: number ) => (
                        <TimeSlotUi slot={row} key={index} index={index}/>


                    ))
                }

                <Grid container spacing={0} >

                </Grid>
                <center>
                    <Button color="success" onClick={timeSlot_Add}>[+] Додати </Button>
                </center>*/}


                <center>
                    <div style={{ paddingBottom: '50px' }}></div>
                </center>


            </div>

    );
};

import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";

import {SoonPage} from "./soon/Soon";
import {Login} from "../components/Login/Login";
import {Logout} from "../components/Login/Logout";
import {Footer} from "../components/footer/Footer";
import {Service_Edit} from "../components/service_edit/Service_Edit";

import {IUserInfo} from "../models/user";
import {Link} from "react-router-dom";
import footer_my_calendar from "../images/ui/footer_my_calendar.png";
import footer_my_service from "../images/ui/footer_my_service.png";
import footer_my_profile from "../images/ui/footer_my_profile.png";
import footer_my_clients from "../images/ui/footer_my_clients.png";
import {AlertColor} from "@mui/material";
import {Service_List} from "../components/service_list/Service_List";
import AddHomeIcon from "@mui/icons-material/AddHome";
import Button from "@mui/material/Button";
import Styles from "../components/service_list/Service_list.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {FooterLinks_MainMenu} from "../components/footer/FooterLinks_MainMenu";
import {Service_List_Public_Demo} from "../components/service_list/Service_List_Public_Demo";

interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    //authFrame: Window | null;
    //handleAdd: (e : React.FormEvent) => void;
}
export const RootPage = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {



    useEffect( () => {
        setFooter(FooterLinks_MainMenu(["bookings", "services", "profile", "clients"]))
    }, [])
    /*
    useEffect(() => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        console.log('Subdomain: ' + subdomain);

    }, []);
     */




    return (
        <div>
            {/*<SoonPage/>*/}




            { userAuth ?
                <>
                    Home Page

                </>

                :
                <Login
                    userAuth={userAuth}
                    setUserAuth={setUserAuth}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    setGlobalMessage={setGlobalMessage}

                />
            }

            <Service_List_Public_Demo
                userAuth={userAuth}
                setUserAuth={setUserAuth}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                footer={footer}
                setFooter={setFooter}
                setGlobalMessage={setGlobalMessage}
                setIsLoading={setIsLoading}
            />
        </div>
    );
};

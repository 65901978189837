import Styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

import React from "react";

import footer_my_calendar from '../../images/ui/footer_my_calendar.png'
import footer_my_service from '../../images/ui/footer_my_service.png'
import footer_my_profile from '../../images/ui/footer_my_profile.png'
import footer_my_clients from '../../images/ui/footer_my_clients.png'
import footer_my_home from '../../images/ui/footer_my_home.png'


//["home", "bookings", "bookingsEmployee", "services", "profile", "clients"]

export const FooterLinks_MainMenu = ( menus : string [] ) => {
    return (
        <>
            {menus.includes("home")?
                <Link to={"/"}>
                    <center><img width={"30px"} src={footer_my_home} alt="Головна"/>
                        <div>Головна</div>
                    </center>
                </Link>
                :<></>
            }
            {menus.includes("bookings")?
                <Link to={"/bookings"}>
                    <center><img src={footer_my_calendar} alt="Мої Записи"/>
                        <div>Мої Записи</div>
                    </center>
                </Link>
                :<></>
            }
            {menus.includes("bookingsEmployee")?
                <Link to={"/bookingsEmployee"}>
                    <center><img src={footer_my_calendar} alt="Записи до мене"/>
                        <div>Записи до мене</div>
                    </center>
                </Link>
                :<></>
            }

            {menus.includes("services")?
                <Link to={"/services"}>
                    <center><img src={footer_my_service} alt="Мої Послуги"/>
                        <div>Мої Послуги</div>
                    </center>
                </Link>
            :<></>
            }
            {menus.includes("profile")?
            <Link to={"/profile"}>
                <center><img src={footer_my_profile} alt="Профайл"/>
                    <div>Профайл</div>
                </center>
            </Link>
            :<></>
            }
            {menus.includes("clients")?
            <Link to={"/"}>
                <center><img src={footer_my_clients} alt="Клієнти"/>
                    <div>Нотатки</div>
                </center>
            </Link>
            :<></>
            }
        </>
    );
};




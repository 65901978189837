import React, {ReactNode, useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';




import {Layout} from "./components/Layout/Layout";
import "./style/global.scss";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import {RootPage} from "./pages/RootPage";

import {IUserInfo} from "./models/user";
import {Service_Add} from "./components/service_add/Service_Add";
import {AlertColor} from "@mui/material";
import {Service_Edit} from "./components/service_edit/Service_Edit";
import {Booking} from "./components/Booking/Booking";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/uk';
import CONFIG from "./config";
import {keyboard} from "@testing-library/user-event/dist/keyboard";
import {API} from "./services/API";
import {Service_Share} from "./components/service_share/Service_Share";
import {UserServicesPage} from "./pages/UserServicesPage";
import {UserProfilePage} from "./pages/UserProfilePage";
import {UserBookingsPage} from "./pages/UserBookingsPage";
import {EmployeeBookingsPage} from "./pages/EmployeeBookingsPage";
import {CompanyBookingsPage} from "./pages/CompanyBookingsPage";



const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


const App: React.FC = () => {
    //const [wind, setWind] = useState< Window | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [userAuth, setUserAuth] = useState<boolean>(false)
    const [subdomain, setSubdomain] = useState<string>("")


    const [userInfo, setUserInfo] = useState<IUserInfo>({phone: "", authToken: "", authDate: 0, name: "", city: "", info: ""})

    const [globalMessage, setGlobalMessage] = useState<{ type: AlertColor, message: String}>({type:"success", message: ""});
    const [footer, setFooter] = useState<ReactNode>(null);
    //const [authFrame, setAuthFrame] = useState< Window | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false);

    //var LoadedResources : {[key:string]:null|number|string} = {authDate: null, authToken: null, inputPhone: null};



    useEffect( () => {
        /*
                const hostname = window.location.hostname;
                setSubdomain(hostname.split('.')[0])


                // auth iframe for subdomains
                const iframe = document.createElement('iframe');
                iframe.id = "aframe"
                iframe.src = CONFIG.AUTH_FRAME;
                iframe.style.display = 'none';

                //iframe.sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"
                //iframe.sandbox ="allow-storage-access-by-user-activation allow-scripts allow-same-origin";
                iframe.setAttribute("sandbox", "allow-storage-access-by-user-activation allow-scripts allow-same-origin");

                iframe.onload = ()=> {
                    console.log('-iframe onload-')
                    setAuthFrame(iframe.contentWindow);
                    iframe.contentWindow?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authDate', method: "get"}), "*")
                    iframe.contentWindow?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'inputPhone', method: "get"}), "*")
                    iframe.contentWindow?.postMessage(JSON.stringify({s: 'zapys.vip', key: 'authToken', method: "get"}), "*")
                }
                document.body.appendChild(iframe);
                window.onmessage = function (e) {
                    console.log('-window on message-')
                    if ( e.data.s && e.data.s == 'zapys.vip') {
                        //console.log(' -m-y- essage ----');
                        console.log(e.data.key, ':', e.data.data);

                        if (e.data.key == "authDate") {
                            let authDate : number = 0;
                            let rawAuthDate: string = e.data.data
                            if (!isNaN(Number(rawAuthDate)) ) {
                                authDate = Number(rawAuthDate);
                            }
                            //setUserInfo( { ...userInfo, authDate : authDate })
                            //setIsLoading( { ...isLoading, authDate : true })
                            LoadedResources.authDate = authDate;


                        }
                        if (e.data.key == "authToken") {
                            //setUserInfo( { ...userInfo, authToken : e.data.data })

                            //if (e.data.data)
                           //     setUserAuth(true);
                            //setIsLoading( { ...isLoading, authToken : true })
                            LoadedResources.authToken = e.data.data || "";
                        }
                        if (e.data.key == "inputPhone") {
                            //setUserInfo( { ...userInfo, phone : e.data.data })
                            //setIsLoading( { ...isLoading, inputPhone : true })
                            LoadedResources.inputPhone = e.data.data || "";
                        }

                    }

                    var allLoaded = true;
                    for (var k of Object.keys(LoadedResources) ) {
                        if (LoadedResources[k] == null) {
                            allLoaded = false;
                            break;
                        }
                    }
                    if (allLoaded) {
                        if ( ( LoadedResources.authToken || "").toString().length > 0)
                             setUserAuth(true);
                        setUserInfo( {
                            authDate : Number(LoadedResources.authDate) || 0,
                            authToken: LoadedResources.authToken?.toString() || "",
                            phone: LoadedResources.inputPhone?.toString() || "",
                            name: "",
                            city: ""
                        });
                        setIsLoaded(true)
                    }
                }



                return () => {
                    //window.removeEventListener('message', {});
                    document.body.removeChild(iframe);
                };

                 */

        const checkAuthorisation = async (authToken: string, authDate : string) => {
            let token = `${authToken};;${authDate}`;

            let data  = await API.authrecheck(token);
            //console.log('----',data);
            if (data.message.error > 0) {
                console.log(data.message)
                setUserAuth(false)
                //setServiceData(null);
                setUserInfo( {authDate : 0, authToken: localStorage.getItem('authToken') || "", phone: localStorage.getItem('inputPhone') || "", name: "", city: "", info: data.info });

            } else  {

                if (data.message.data.authToken && data.message.data.sms_code) {
                    setUserAuth(true)
                    // data.message.data.authToken
                    // data.message.data.phone
                    // data.message.data.sms_code
                    // data.message.data.name


                    //console.log(data.message)

                    let authDate: number = 0;
                    let rawAuthDate: string = data.message.data?.sms_send_date;
                    if (!isNaN(Number(rawAuthDate))) {
                        authDate = Number(rawAuthDate);
                    }
                    setUserInfo({
                        authDate: authDate,
                        authToken: data.message.data.authToken || "",
                        phone: data.message.data.phone || "",
                        name: data.message.data.name || "",
                        info: data.message.data.info || "",
                        city: data.message.data.city || ""
                    });
                }
            }
            setIsLoaded(true);
        }

        checkAuthorisation(localStorage.getItem('authToken') || "", localStorage.getItem('authDate') || "");

    }, [])


    return (
        <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                <CssBaseline />
                <div className="App">
                    { isLoaded ?
                        <Layout
                            userAuth={userAuth}
                            setUserAuth={setUserAuth}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            footer={footer}
                            globalMessage={globalMessage}
                            setGlobalMessage={setGlobalMessage}
                            isLoading={isLoading}
                        >
                                <Routes>
                                    <Route path="/" element={
                                        <RootPage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/services" element={
                                        <UserServicesPage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/profile" element={
                                        <UserProfilePage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/bookings" element={
                                        <UserBookingsPage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/bookingsEmployee" element={
                                        <EmployeeBookingsPage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/bookingsService/:id" element={
                                        <CompanyBookingsPage
                                            userAuth={userAuth}
                                            setUserAuth={setUserAuth}
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                            footer={footer}
                                            setFooter={setFooter}
                                            setGlobalMessage={setGlobalMessage}
                                            setIsLoading={setIsLoading}

                                        />}
                                    />

                                    <Route path="/s/:url_name" element={
                                        <Booking
                                            userAuth={userAuth}
                                               setUserAuth={setUserAuth}
                                               userInfo={userInfo}
                                               setUserInfo={setUserInfo}
                                               footer={footer}
                                               setFooter={setFooter}
                                               setGlobalMessage={setGlobalMessage}
                                               setIsLoading={setIsLoading}

                                        />
                                    }
                                    />

                                    <Route path="/services/add" element={
                                        userAuth ?
                                            <Service_Add
                                                userAuth={userAuth}
                                                setUserAuth={setUserAuth}
                                                userInfo={userInfo}
                                                setUserInfo={setUserInfo}
                                                footer={footer}
                                                setFooter={setFooter}
                                                setGlobalMessage={setGlobalMessage}
                                            /> : <>Будь ласка авторизуйтесь <a href={"/"}>На головну</a> </>
                                    }
                                    />

                                    <Route path="/services/edit/:id" element={
                                        userAuth ?
                                            <Service_Edit
                                                userAuth={userAuth}
                                                setUserAuth={setUserAuth}
                                                userInfo={userInfo}
                                                setUserInfo={setUserInfo}
                                                footer={footer}
                                                setFooter={setFooter}
                                                setGlobalMessage={setGlobalMessage}
                                                setIsLoading={setIsLoading}
                                            /> : <>Будь ласка авторизуйтесь <a href={"/"}>На головну</a> </>
                                    }
                                    />

                                    <Route path="/services/share/:id" element={
                                        userAuth ?
                                            <Service_Share
                                                userAuth={userAuth}
                                                setUserAuth={setUserAuth}
                                                userInfo={userInfo}
                                                setUserInfo={setUserInfo}
                                                footer={footer}
                                                setFooter={setFooter}
                                                setGlobalMessage={setGlobalMessage}
                                                setIsLoading={setIsLoading}
                                            /> : <>Будь ласка авторизуйтесь <a href={"/"}>На головну</a> </>
                                    }
                                    />

                                </Routes>




                        </Layout>
                        : <>Loading...</>
                    }
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;

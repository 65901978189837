import axios from "axios";
import {ITime} from "../interfaces/interfaces";
export class Utils {

    constructor( ) {
    }

    public escapeHtml (unsafe: string){
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    public clearSymbol (unsafe: string){
        return unsafe
            .replace(/&/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/"/g, "")
            .replace(/'/g, "");
    }

    public async requestPost  ( url : string, data : object, token: string ) {
        try {
            //let data_raw = await axios({ method: 'post', url: url, params:data });

            let data_raw = await axios.post(url, data, {
                headers: {
                    'Authorization': `${token}`
                }  });
            //console.log(data_raw)
            //console.log(data_raw.data)
            return data_raw.data;
        } catch (e) {
            console.log(e)
            return false
        }

    }

    public async requestPostForm  ( url : string, data : object, token: string ) {
        try {
            //let data_raw = await axios({ method: 'post', url: url, params:data });

            let data_raw = await axios.post(url, data, {
                headers: {
                    'Authorization': `${token}`
                    //"Content-Type": "multipart/form-data"
                }  });
            //console.log(data_raw)
            //console.log(data_raw.data)
            return data_raw.data;
        } catch (e) {
            console.log(e)
            return false
        }

    }

    public async requestGetExt  ( url : string ) {
        try {
            //let data_raw = await axios ({ method: 'post', url: url, params:data });

            let data_raw = await axios.get(url);
            //let data_raw = await axios.post(url, data, {
                //headers: {
                //    'Authorization': `${token}`
                //}  });
            //console.log(data_raw)
            //console.log(data_raw.data)
            return data_raw.data;
        } catch (e) {
            console.log(e)
            return false
        }

    }


    public dateTime2Date ( dateTime_ : Date | undefined) {

        if (dateTime_ == undefined) return "";

        const dateTime = new Date(dateTime_)

        let d = dateTime.getDate().toString();
        let m = (dateTime.getMonth() +1).toString();
        let y = dateTime.getFullYear().toString();

        if (d.length == 1) d = `0${d}`
        if (m.length == 1) m = `0${m}`

        return `${y}-${m}-${d}`
    }

    public iTime2str ( time: ITime) {

        if ( time.h == 0 ) {
            return `${time.m} хв`
        } else {
            let res = `${time.h} год`
            if ( time.m > 0) {
                res += ` ${time.m} хв`
            }
            return res;
        }
    }

    public minutes2str ( minutes : number) {

        let h = Math.floor(minutes / 60);
        let m = (minutes - h * 60)
        let m_str = m.toString()

        if (m_str.length == 1)
            m_str = `0${m_str}`

        if ( h == 0 ) {
            return `${m_str} хв`
        } else {
            let res = `${h} год`
            if ( m > 0) {
                res += ` ${m_str} хв`
            }
            return res;
        }
    }

    public timeSlot2str ( timeSlot: number) {
        let h = Math.floor(timeSlot / 60);
        let m = (timeSlot - h * 60).toString();

        if (m.length == 1)
            m = `0${m}`

        return`${h}:${m}`
    }

    public formatPhoneNumber ( phoneNumber : string) {
        // Remove any non-digit characters
        const cleanedNumber = phoneNumber.replace(/\D/g, '');

        // Check if the phone number is valid
        if (cleanedNumber.length !== 12) {
            return 'Invalid phone number';
        }

        // Format the phone number
        const countryCode = cleanedNumber.substring(0, 2);
        const areaCode = cleanedNumber.substring(2, 5);
        const firstPart = cleanedNumber.substring(5, 8);
        const secondPart = cleanedNumber.substring(8);

        return `+${countryCode} ${areaCode} ${firstPart} ${secondPart}`;
    }

}
import Styles from "./Service_Edit.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'
import {FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {IGmap, IService, iWizardInfo} from "../../interfaces/interfaces";

//import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";


import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import Grid from "@mui/material/Unstable_Grid2";
import {API} from "../../services/API";

const utils = new Utils();



interface Errors {

}

//
// interface Step1 {
//     url_name: string;
//     service_type: string;
//     created_by: string;
//     name: string;
//     city: string;
//     address: string;
//     phone: string;
//     website: string;
//     map: IGmap,
// }

interface Props{
    serviceData: IService;
    setServiceData: React.Dispatch<React.SetStateAction<IService>>;
    //handleAdd: (e : React.FormEvent) => void;
}


export const Step1 = ({serviceData, setServiceData} : Props) => {



    const [inputName, setInputName] = useState<string>(serviceData.name || "")
    const [inputCity, setInputCity] = useState<string>(serviceData.city || "")
    const [inputAddress, setInputAddress] = useState<string>(serviceData.address || "")
    const [inputPhone, setInputPhone] = useState<string>(serviceData.phone || "")
    const [inputWebSite, setInputWebSite] = useState<string>(serviceData.website || "")
    const [mapCenter, setMapCenter] = useState<IGmap>(serviceData.map || {lat: 49.83134102148687, lng: 24.07874763017847})
    const [mapMarker, setMapMarker] = useState<IGmap>(serviceData.map || {lat: -1, lng: -1})
    const [mapInfo, setMapInfo] = useState<{[key: string]:string} >({})


    const [errorValid1, setErrorValid1] = useState<string>("")
    const [errorValid2, setErrorValid2] = useState<string>("")
    const [errorValid3, setErrorValid3] = useState<string>("")
    const [errorValid4, setErrorValid4] = useState<string>("")
    const [errorValid5, setErrorValid5] = useState<string>("")
    const [errorValid6, setErrorValid6] = useState<string>("")

    //const [goodValid1, setGoodValid1] = useState<string>("")
    const [map, setMap] = useState<google.maps.Map>()

    const navigate = useNavigate();

    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st.label
        }
        //return {label: "", value : ""};
        return ""
    }

    const getCity = ( city_type : string) => {
        for (var c of CONFIG.CITIES) {
            if (c.value == city_type)    return c.label
        }
        return ""
    }

    const ValidateName = async ( ) => {
    }

    const ValidateInput = async ( key: string ) => {
        //e.target.value
        //console.log(key)
        let allIsValid : boolean = true;

        var obj: { [key: string]: string|IGmap } = {};

        var inputsGrabs : { [key: string]: string|IGmap } = {
            "name": utils.clearSymbol(inputName),
            "city": utils.clearSymbol(inputCity),
            "address": utils.clearSymbol(inputAddress),
            "phone": utils.clearSymbol(inputPhone),
            "website": utils.clearSymbol(inputWebSite),
            "map": mapMarker
        }
        //console.log(key)
        if ( inputsGrabs[key] ) {
                obj[key] = inputsGrabs[key];

        } else {
            allIsValid = false;
        }



/*
        if ( key == "name") {
            //
        } else if ( key == "city") {
            //obj[ key ] = inputCity;
        } else if ( key == "address") {
            //obj[ key ] = inputAddress;
        } else if ( key == "phone") {
            //obj[ key ] = inputPhone;
        }  else if ( key == "website") {
            //obj[ key ] = inputWebSite;
        } else if ( key == "map") {
            //obj[ key ] = inputMap;
        } else {
            //allIsValid = false;
        }
*/
        //console.log(serviceData)
        //console.log(obj)

        if (allIsValid && obj && Object.entries(obj).length > 0) {
            //updateService(obj)
            setServiceData ( {
                ...serviceData,
                ...obj,
            })
        }


        //var obj: Record<string, string> = {};
        //obj[ key ] = value;
        //let allIsValid = true;

        // TODO: ADD validations

        //console.log('-----')
        //console.log(Object.entries(obj))

        //if (allIsValid && Object.entries(obj).length > 0) {
            //updateSerivce(obj)
        //}



    }

    const mapRef = useRef()
    const { isLoaded } = useLoadScript(
        {
        googleMapsApiKey: CONFIG.GMAP_KEY,
    });
    const center = useMemo(() => ({ lat: mapCenter.lat, lng: mapCenter.lng }), []);



    const handelClickSetMarkerByAddress  = async ()=> {
        let address : string = `${inputCity}, ${inputAddress}`;

        let found_addr = await API.gmap_address_by_address( address );

        //console.log(found_addr);
        setMapInfo({});
        if (found_addr.results.length > 0) {
            //let pos:IGmap = {lat: ev.latLng?.lat() || -1, lng: ev.latLng?.lng() || -1};
            let pos:IGmap = found_addr.results[0].geometry.location;
            console.log(pos)
            setMapMarker(pos);
            //ValidateInput("map")
            setMapCenter(pos);

            map?.panTo(found_addr.results[0].geometry.location);

        }



    }


   const handelClickOnMap  = async (ev: google.maps.MapMouseEvent)=> {
        //console.log("latitide = ", ev.latLng?.lat());
        //console.log("longitude = ", ev.latLng?.lng());

        //console.log(ev)
        if (ev.latLng ) {
            let pos:IGmap = {lat: ev.latLng?.lat() || -1, lng: ev.latLng?.lng() || -1};
            console.log(pos)
            setMapMarker(pos);
            //ValidateInput("map")
            setMapCenter(pos);

            map?.panTo(ev.latLng);

            let found_addr = await API.gmap_address_by_coords(pos );
            if (found_addr.status == "OK"){
                const marker_full_address = found_addr.results[0].formatted_address;
                //console.log(found_addr.results[0].address_components);

                let mapAddressObj : { [key: string]: string } = {};

                mapAddressObj.full_address = found_addr.results[0].formatted_address


                for (var r of found_addr.results[0].address_components) {
                    //console.log(r.short_name, r.types)
                    switch ( r.types[0] ){
                        case 'route':
                            mapAddressObj.street = r.short_name;
                            break;
                        case 'street_number':
                            mapAddressObj.building = r.short_name;
                            break;
                        case 'locality':
                            mapAddressObj.city = r.short_name;
                            break;
                        case 'administrative_area_level_1':
                            mapAddressObj.state = r.short_name;
                            break;
                        case 'country':
                            mapAddressObj.country = r.short_name;
                            break;
                        case 'postal_code':
                            mapAddressObj.zip = r.short_name;
                            break;
                    }

                }



                setMapInfo(mapAddressObj );


                //found_addr.results[0].
            }
            console.log(found_addr)
        }
   }


    //coords by address
    // https://maps.googleapis.com/maps/api/geocode/json?address=%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,%D0%A2%D0%B0%D1%80%D0%BD%D0%B0%D0%B2%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE,45&key=AIzaSyAh22GR4FuMOu8YapM3I7QzOH0jGK4Xyls

    // adddress by coords
    // https://maps.googleapis.com/maps/api/geocode/json?latlng=49.82937529798446,24.037607522055417&key=AIzaSyAh22GR4FuMOu8YapM3I7QzOH0jGK4Xyls

    useEffect( () => {
        ValidateInput("map")

    }, [mapMarker])



    return (
    <div className={Styles.module_block}>

        <Grid container spacing={0} className={Styles.s1_static}>
            <Grid xs={6}  spacing={0} style={{textAlign:"left"}}>
                <span>Послуга:</span> {getServiceType(serviceData.service_type || "")}
            </Grid>

            <Grid xs={6}  spacing={0} style={{textAlign:"right"}}>
                <span >URL запису:</span> {`${serviceData.url_name}.zapys.vip`}
            </Grid>
        </Grid>




       {/* <div className={Styles.input_group}>
            <TextField
                fullWidth
                label="Назва для URL адреси"
                id="step1_urlname"
                placeholder={""}
                variant="standard"
                disabled
                value={`${serviceData.url_name}.zapys.vip`}
            />
        </div>

        <div className={Styles.input_group}>
            <TextField
                fullWidth
                label="Ваш бізнес "
                id="step1_service_type"
                placeholder={""}
                variant="standard"
                disabled
                value={getServiceType(serviceData.service_type || "")}
            />
        </div>*/}



            <div className={Styles.input_group}>
                <TextField
                    fullWidth
                    error={errorValid1.length > 0}
                    label="Назва / відділення / ФОП"
                    id="step1_name"
                    placeholder={"Наприклад: Шиномонтаж у Івана №3"}
                    variant="outlined"
                    value={inputName}
                    onChange={(e)=>setInputName(e.target.value)}
                    onBlur={()=>ValidateInput('name')}

                />
                {!errorValid1 ? "" : <div className={Styles.validation_err}><span>{errorValid1}</span> </div>}
            </div>


            <div className={Styles.input_group}>
                <FormControl fullWidth variant="outlined"  sx={{ m: 0, minWidth: 120 }} size="small" >
                    <InputLabel id="demo-simple-select-label">Місто</InputLabel>
                    <Select
                        style={{borderRadius: '5px 5px 5px 5px', textAlign: "right", color: '#F6C445'}}
                        labelId="step1_city"
                        id="step1_city"
                        label="Місто"
                        variant="outlined"
                        value={inputCity}
                        onChange={(e)=>setInputCity(e.target.value) }
                        error={errorValid2.length > 0}
                        onBlur={()=>ValidateInput('city')}


                    >
                        {CONFIG.CITIES.map((row) => (
                            <MenuItem
                                key={row.value}
                                value={row.value}

                            >
                                {row.label}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>

                {
                    !errorValid2 ? "" : <div className={Styles.validation_err}><span>{errorValid2}</span> </div>
                    //<div className={Styles.validation_ok}><span>Добре</span> </div>
                }
            </div>


            <div className={Styles.input_group}>
                <TextField
                    fullWidth
                    error={errorValid3.length > 0}
                    //helperText={!errorValid3 ? "" : <div className={Styles.validation_err}><span>{errorValid3}</span> </div>}

                    label="Адреса"
                    id="step1_address"
                    placeholder={"Наприклад: вул. Зелена, 420"}
                    value={inputAddress}
                    onChange={(e)=>setInputAddress(e.target.value)}
                    onBlur={()=>ValidateInput('address')}

                />
                {!errorValid3 ? "" : <div className={Styles.validation_err}><span>{errorValid3}</span> </div>}
            </div>

            <div className={Styles.input_group}>
                <TextField
                    fullWidth
                    error={errorValid4.length > 0}
                    //helperText={!errorValid4 ? "" : <div className={Styles.validation_err}><span>{errorValid4}</span> </div>}
                    label="Телефон"
                    id="step1_phone"
                    placeholder={"Наприклад: +38 012 444 55 66"}
                    value={inputPhone}
                    onChange={(e)=>setInputPhone(e.target.value)}
                    onBlur={()=>ValidateInput('phone')}

                />
                {!errorValid4 ? "" : <div className={Styles.validation_err}><span>{errorValid4}</span> </div>}
            </div>

            <div className={Styles.input_group}>
                <TextField
                    fullWidth
                    error={errorValid5.length > 0}

                    label="Веб-сторінка"
                    id="step1_website"
                    placeholder={`Наприклад: https://${serviceData.url_name}.zapys.vip`}
                    value={inputWebSite}
                    onChange={(e)=>setInputWebSite(e.target.value)}
                    onBlur={()=>ValidateInput('website')}


                />
                {!errorValid5 ? "" : <div className={Styles.validation_err}><span>{errorValid5}</span> </div>}
            </div>

            <div style={{padding: "2px 5px"}}>
                {/*<Wrapper apiKey={"AIzaSyAh22GR4FuMOu8YapM3I7QzOH0jGK4Xyls"} >
                    <Map style={{ width: '100px', height:'100px' }}/>
                </Wrapper>
                */}

                {!isLoaded ? (
                    <p>Loading...</p>
                ) : (

                    <Grid container spacing={0}>
                        <Grid xs={6}  spacing={0}>
                            <GoogleMap
                                mapContainerClassName={Styles.mapContainer}
                                center={center}
                                zoom={mapMarker.lat > 0 ? 15 : 10 }
                                onLoad={(map)=>setMap(map)}
                                clickableIcons={false}
                                options={{disableDefaultUI : true, fullscreenControl: true, panControl: true, styles: [
                                        {
                                            "featureType": "poi",
                                            "elementType": "labels",
                                            "stylers": [
                                                {
                                                    "visibility": "off"
                                                }
                                            ]
                                        }]}}
                                onClick={ev => {
                                    handelClickOnMap(ev);

                                }}

                            >
                                {mapMarker.lat > 0 && mapMarker.lng > 0 ?
                                    <Marker
                                        position={{ lat: mapMarker.lat, lng: mapMarker.lng }}
                                        icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
                                    /> : <></>
                                }


                            </GoogleMap>
                        </Grid>
                        <Grid  xs={6}  spacing={0}>
                            { Object.entries(mapInfo).length > 0 ?  <>
                                <div >Мітка на карті</div>
                                <div >Місто: {mapInfo.city} ({mapInfo.state} )</div>
                                <div >Адреса: {mapInfo.street}, {mapInfo.building} </div>
                                <div >Почтовий код: {mapInfo.zip} </div>
                                <div >Країна: {mapInfo.country} </div>
                                <hr/>
                                <div >{mapInfo.full_address} </div>
                                </>
                                :
                                <>
                                    Мітку не встановлено.

                                </>
                            }
                            <Button onClick={handelClickSetMarkerByAddress}> Встановити за вказаною адресою </Button>

                        </Grid>
                    </Grid>





                )}


            </div>

            <center>
                <div style={{ paddingBottom: '50px' }}></div>
            </center>









    </div>
  );
};

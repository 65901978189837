import React, {ReactNode} from "react";
import {Header} from "../header/Header";
import {Footer} from "../footer/Footer";
import Styles from "./Layout.module.scss"
import {IUserInfo} from "../../models/user";
import {Notification} from "./Notification";
import Grid from '@mui/material/Unstable_Grid2';
import {AlertColor, CircularProgress, Snackbar, Stack} from "@mui/material"; // Grid version 2

interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    children: ReactNode;
    globalMessage: {type: AlertColor, message: String};
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    isLoading: boolean;

}
//isLoading? Styles.blur:""

export const Layout = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, globalMessage, setGlobalMessage, children, isLoading} : Props) => (

    <div className={Styles.layout}>
        <Header/>

        <div className={ isLoading ? Styles.loading : Styles.hide}>
            <CircularProgress color="success" />
        </div>
        <div className={`${footer == null ? Styles.layoutContainer : Styles.layoutContainer_footer} ${isLoading ? Styles.blur : ''}`}>
            {children}
        </div>

        <Footer
            userAuth={userAuth}
            setUserAuth={setUserAuth}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            footer={footer}
        />

        <Notification
            globalMessage={globalMessage}
            setGlobalMessage={setGlobalMessage}
        />

    </div>
);
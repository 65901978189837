import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./BookingsUsers.module.scss";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, IconButton, styled, Typography} from "@mui/material";
import {API} from "../../services/API";
import {FooterLinks_MainMenu} from "../footer/FooterLinks_MainMenu";
import {IBooking, IService} from "../../interfaces/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import IMG_BTN_PAUSE from "../../images/ui/service_btn_pause.png";
import IMG_BTN_PLAY from "../../images/ui/service_btn_play.png";
import IMG_BTN_STATS from "../../images/ui/service_btn_stats.png";
import IMG_BTN_SHARE from "../../images/ui/service_btn_share.png";
import IMG_BTN_CONFIG from "../../images/ui/service_btn_config.png";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from "dayjs";



import Paper from '@mui/material/Paper';
import {ViewState, GroupingState, IntegratedGrouping, AppointmentModel, Resource} from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    DayView,
    TodayButton,
    ViewSwitcher,
    WeekView,
    MonthView,
    Toolbar,
    Resources,
    GroupingPanel,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    CurrentTimeIndicator

} from '@devexpress/dx-react-scheduler-material-ui';
import { teal, indigo } from '@mui/material/colors';



const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface itemData { key: string; row: IBooking }

export const BookingsCompany = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    //const [inputDomain, setInputDomain] = useState<string>("")

    const [userBookings, setUserBookings] = useState<IBooking[]>([])
    const params = useParams();


    const [schedulerData, setSchedulerData] = useState<AppointmentModel[]>([])
    const [resources, setResources] = useState< Resource[]>(
        [
            {
                fieldName: 'employee',
                title: 'Працівник',
                instances: [{ text: 'R 420',    id: 'Room 420' }],
            },
            {
                fieldName: 'location',
                title: 'місце',
                instances: [{ text: 'R 420',    id: 'Room 420' }],
            }
        ]
    )
    const [maxMinTime, setMaxMinTime] = useState<{ startH: number, endH: number }>({ startH: 0, endH: 0 })


    const currentDate = '2023-11-20';
    /*var schedulerData = [
        { startDate: '2023-11-19T09:45', endDate: '2023-11-19T11:00', title: 'Meeting', location: "Room 420", employee:1 },
        { startDate: '2023-11-20T09:45', endDate: '2023-11-20T11:00', title: 'Meeting', location: "Room 420", employee:1 },
        { startDate: '2023-11-20T12:00', endDate: '2023-11-20T18:30', title: 'Go to a gym', location: "Room 420", employee:2 },
        { startDate: '2023-11-20T12:00', endDate: '2023-11-20T18:30', title: 'Go to a gym', location: "Room 420", employee:3 },
    ];*/



    // const resources1 = [
    //     {
    //         fieldName: 'employee',
    //         title: 'Працівник',
    //         instances: [],
    //     },
    //     {
    //         fieldName: 'location',
    //         title: 'місце',
    //         instances: [{ text: 'R 420',    id: 'Room 420' }],
    //     }
    // ]

    const grouping = [{
        resourceName: 'employee'
    }]


    const handleBack = ( ) => {
        navigate(-1)
    };
    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
    }


    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st
        }
        return {label: "", value : ""};

    }

    const getEmployeeByPhone = ( serviceData: IService, phone: string ) => {
        for ( var empl of serviceData?.employees || []) {
            if ( empl.phone == phone) return empl;
        }
        return null;
    }

    const getTaskBySid = ( serviceData: IService, sid: string ) => {
        for ( var los of serviceData?.list_of_services || []) {
            if ( los.sid == sid) return los;
        }
        return null;
    }


    const fetchData = async () => {
        let service_id = (params.id || "").toString();
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.bookings_byService(service_id,token);
        //console.log(data.message.data)
        console.log(data.message.data.events)

        setResources(
            [
                {
                    fieldName: 'employee',
                    title: 'Працівник',
                    instances: data.message.data.employees,
                }
            ]);

        console.log(data.message.data.maxMinTime)
        setMaxMinTime(data.message.data.maxMinTime)
        //.maxMinTime.startH

        setSchedulerData(data.message.data.events)
        //setUserBookings(data.message.data)
        setIsLoading(false);
    }




    useEffect( () => {
        setIsLoading(true);
        fetchData();


        // setTimeout(()=> {
        //     console.log('aaa');
        //
        //     setSchedulerData ( [...schedulerData, {
        //         startDate: '2023-11-20T03:30',
        //         endDate: '2023-11-20T05:00',
        //         title: 'test',
        //         location: "Room 420",
        //         employee: 1
        //     }] )
        // }, 2000);
    }, [])




/*

    const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
        [`&.Demo-flexibleSpace`]: {
            flex: 'none',
        },
        [`& .Demo-flexContainer`]: {
            display: 'flex',
            alignItems: 'center',
        },
    }));

    const FlexibleSpace = (({ ...restProps }) => (
        <StyledToolbarFlexibleSpace {...restProps} >
            <div >
            </div>
        </StyledToolbarFlexibleSpace>
    ));*/


    return (
        <div>
            <center><h1>Всі Записи</h1></center>

            <Paper>
                <Scheduler
                    data={schedulerData}
                    locale={"uk-UK"}
                    height={550}
                >
                    <ViewState
                        defaultCurrentDate={currentDate}
                    />

                    <GroupingState
                        grouping={grouping}
                    />

                    <DayView
                        startDayHour={maxMinTime.startH ? maxMinTime.startH : 0}
                        endDayHour={maxMinTime.endH ? maxMinTime.endH : 18}
                    />
                    <WeekView
                        startDayHour={maxMinTime.startH}
                        endDayHour={maxMinTime.endH}
                    />
                    <WeekView
                        name="work-week"
                        displayName="Work Week"
                        excludedDays={[0, 6]}
                        startDayHour={maxMinTime.startH}
                        endDayHour={maxMinTime.endH}
                    />
                    <MonthView />


                    <Toolbar/>

                    <ViewSwitcher />
                    <TodayButton />
                    <DateNavigator />

                    <Appointments />
                    <Resources
                        data={resources}
                        mainResourceName="employee"
                    />
                    <IntegratedGrouping />
                    <GroupingPanel />

                    <AppointmentTooltip
                        showCloseButton

                    />
                    <CurrentTimeIndicator
                        shadePreviousCells={true}
                        shadePreviousAppointments={true}
                        updateInterval={10000}
                    />
                </Scheduler>
            </Paper>


        </div>
    );
}
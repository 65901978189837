import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./Service_Add.module.scss";
import { useNavigate } from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';

// import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import {
    AlertColor,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Auth from "../../services/auth";

import AddHomeIcon from '@mui/icons-material/AddHome';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {API} from "../../services/API";

const utils = new Utils();


interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    //handleAdd: (e : React.FormEvent) => void;
}



export const Service_Add = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage} : Props) => {

    const [inputDomain, setInputDomain] = useState<string>("")
    const [inputServiceType, setInputServiceType] = useState<string>("")

    const [errorValid1, setErrorValid1] = useState<string>("")
    const [goodValid1, setGoodValid1] = useState<string>("")
    const [errorValid2, setErrorValid2] = useState<string>("")

    const [registrationDone, setRegistrationDone] = useState<boolean>(false)


    const navigate = useNavigate();

    var serviceData = {
        url_name: "",
        service_type: "",
    };


    const handleBack = ( ) => {
        navigate(-1)
    };

    const handleNext = async () => {
        // TODO: validate
        console.log(inputDomain, inputServiceType)

        // validate name
        //let isValid = await ValidateName();

        //let isValidService = ValidateServiceType();
        //if (isValidService == false)
         //   isValid = false;

        //if (isValid)
            ServiceAdd();

    };
    const ValidateServiceType = () => {
        if (!inputServiceType) {
            setErrorValid2("Виберіть сферу бізнесу");
            return false;
        } else {
            setErrorValid2("");
            return true;
        }
    }

    const ValidateName = async ( ) => {


        //let req = {url_name: inputDomain};
        //let req_res = await utils.requestPost(`${CONFIG.API}/services/checkName`, req, "");
        let req_res = await API.service_checkName(inputDomain)

        //console.log(req_res)
        if (req_res.message.error == 1) {
            setGoodValid1("")
            setErrorValid1(req_res.message.message)
            return false;
        }

        if (req_res.message.error == 0) {
            setErrorValid1("")
            setGoodValid1(req_res.message.message)
            return true;
        }

    }


    const ServiceAdd = async ( ) => {
        //let req = {
         //       url_name: inputDomain,
        //        service_type: inputServiceType
        //}

        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let req_res = await API.service_add(inputDomain, inputServiceType, token)

       // let req_res = await utils.requestPost(`${CONFIG.API}/services/add`, req, `${userInfo.authToken};;${userInfo.authDate}`);

        //console.log(req_res)
        if (req_res.message.error > 0 ) {

            let error_message = "Service add: Unknown Error";
            if (req_res.message && req_res.message.message)
                error_message = req_res.message.message;

            if (req_res.message.error == 401) {
                Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
            }

            if (req_res.message.error == 101) {
                setErrorValid1(req_res.message.message)
                setGoodValid1("")
            }
            if (req_res.message.error == 102) {
                setErrorValid2(req_res.message.message)

            }

            setGlobalMessage({type: "error", message:error_message} )

        }else {
                setErrorValid1("");
                setErrorValid2("");

        }



        if (req_res.message.error == 0) {
            setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
            setRegistrationDone(true);
            //setErrorValid1("")
            //setGoodValid1(req_res.message.message)
           // return true;
        }

    }


    useEffect( () => {
        setFooter(null)
    }, [])

    return (
        <div className={Styles.module_block}>
            { !registrationDone ? <>

                <IconButton onClick={handleBack}  color="secondary" aria-label="add an alarm">
                    <img src={IMG_BTN_BACK} alt="Назад" title="Назад" />
                </IconButton>

                <span className={Styles.title} >Реєстрація послуги</span>
                <div className={Styles.input_group}>
                    <TextField
                        fullWidth
                        error={errorValid1.length > 0}
                        //helperText={!errorValid1 ? "" : <div className={Styles.validation_err}><span>{errorValid1}</span> </div>}
                        label="Назва для URL адреси  (латинські літери та цифри)"
                        id="step1_urlname"
                        placeholder={"Наприклад shyna"}
                        onChange={(e)=>setInputDomain(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="end" className={Styles.inpPrefixHide}></InputAdornment>,
                            endAdornment: <InputAdornment position="start" ><span className={Styles.inpSuf}>.ZAPYS.VIP</span></InputAdornment>,

                        }}
                        onBlur={ValidateName}
                    />
                    {!errorValid1 ? "" : <div className={Styles.validation_err}><span>{errorValid1}</span> </div>}
                    {!goodValid1 ? "" : <div className={Styles.validation_ok}><span>{goodValid1}</span> </div>}

                </div>

                <div className={Styles.input_group}>
                    <FormControl fullWidth variant="filled"  sx={{ m: 0, minWidth: 120 }} size="small" >
                        <InputLabel id="demo-simple-select-label">Ваш бізнес</InputLabel>
                        <Select
                            error={errorValid2.length > 0}
                            style={{borderRadius: '10px 10px 0px 0px'}}
                            labelId="demo-simple-select-label"
                            id="step1_biz_type"
                            value={inputServiceType}
                            label="Ваш бізнес"
                            onChange={(e)=>setInputServiceType(e.target.value)}
                            onClose={(e) => setErrorValid2("")}

                        >
                            { CONFIG.SERVICE_TYPES.map((row) => (
                                <MenuItem
                                    key={row.value}
                                    value={row.value}

                                >
                                    {row.label}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    {!errorValid2 ? "" : <div className={Styles.validation_err}><span>{errorValid2}</span> </div>}
                </div>
                <center>
                    <div style={{ padding: '10px' }}></div>
                    <Button onClick={handleNext} variant="contained" style={{backgroundColor: '#A0E548', color: 'black'}}  size="large" endIcon={<AddHomeIcon />}>
                        <span className={Styles.btnText}>Створити Послугу</span>

                    </Button>
                </center>

                <div className={Styles.block}>
                    <p>Для власників бізнесу  у сфері надання послуг, хто бажає мати власну, гнучку, онлайн систему запису та обліку черги клієнтів.  </p>
                    <p>Переваги:</p>
                    <ul>
                        <li>пряме посилання на бронювання місця  у черзі для вашого бізнесу</li>
                        <li>налаштування ваших працівників, їх робочого часу та кваліфікації</li>
                        <li>гнучка конфігурація часових слотів бронювання </li>
                        <li>СМС сповіщення для клієнтів та працівників.</li>
                        <li>Статистика, звіти та контроль</li>
                        <li>Карта клієнта</li>
                    </ul>
                    <p>А основне це зручність для клієнтів!</p>

                </div>
            </> : <>
                <center>

                    <CheckCircleIcon color="success" fontSize="inherit" className={Styles.doneIco}/>

                    <span className={Styles.title} >Послугу зареєстровано</span>
                </center>
                <div className={Styles.block}>
                    <p><strong>Вітаємо!</strong></p>
                    <p>
                        Вашу послугу зареєстровано у системі.
                    </p>
                    <p>
                        Увага! Необхідна активація щоб послуга запрацювала, після чого Ви отримаєте пряме URL посилання,
                        QR-код та віджет-код і це можна буде використовувати для запису/бронювання Ваших послуг.
                    </p>
                    <p>
                        Для активації послуги потрібно:
                    </p>
                        <ul>
                            <li>Надати більше інформації про Ваші послуги, такі як: котактні дані, робочі години, послуги та їх тривалість, праціники та їх робочі зміни. Це можна зробити за допомогою редагування послуги на головній сторінці </li>
                            <li>Після заповнення всіх даних, Адміністрація Zapys.VIP перевірить дані та активує послугу </li>
                        </ul>


                </div>
                <center>
                    <IconButton onClick={handleBack}  color="secondary" aria-label="add an alarm" className={Styles.backBtnText}>
                        <img src={IMG_BTN_BACK} alt="На головну" title="На головну" /> На Головну
                    </IconButton>
                </center>
            </>}
        </div>

  );
};

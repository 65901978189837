import Styles from "./Service_Edit.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'

import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {Step4_ConfigEmployee} from "./Step4_ConfigEmployee";

//import Select from 'react-select'

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'
import {
    AlertColor,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import {
    IEmployee, IEmployment,
    IGmap,
    IListedService,
    IService,
    IUser,
    iWizardInfo,
    IWorkingSlot
} from "../../interfaces/interfaces";

import AccountCircle from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import Grid from "@mui/material/Unstable_Grid2";

import { IMaskInput } from 'react-imask';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import {API} from "../../services/API";
import Auth from "../../services/auth";

const utils = new Utils();



interface Errors {

}

//IWorkingSlot

// interface Step2 {
//     url_name: string;
//     service_type: string;
//     created_by: string;
//     name: string;
//     city: string;
//     address: string;
//     phone: string;
//     website: string;
//     map: IGmap,
// }



interface Props{
    serviceData: IService;
    setServiceData: React.Dispatch<React.SetStateAction<IService>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    //handleAdd: (e : React.FormEvent) => void;
}



interface EmployeeProps {
    employee: IEmployee;
    index: number;
}

interface DialogProps {
    open: boolean;
    index: number;
}


interface itemData { key: string; row: IEmployee; }


// Mask for phone input
interface inputPhoneProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const inputPhoneMask = React.forwardRef<HTMLInputElement, inputPhoneProps>(
    function inputPhoneMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[0]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

// end mask for phone input


export const Step4 = ({serviceData, setServiceData, setGlobalMessage, setIsLoading, userInfo, setUserInfo} : Props) => {
    const [showConfigDialog, setShowConfigDialog] = useState<DialogProps>({open: false, index: 0})

/*
    const [inputName, setInputName] = useState<string>(serviceData.name || "")

    const [inputCity, setInputCity] = useState<string>(serviceData.city || "")
    const [inputAddress, setInputAddress] = useState<string>(serviceData.address || "")

    const [inputWebSite, setInputWebSite] = useState<string>(serviceData.website || "")
    const [mapCenter, setMapCenter] = useState<IGmap>(serviceData.map || {lat: 49.83134102148687, lng: 24.07874763017847})
    const [mapMarker, setMapMarker] = useState<IGmap>(serviceData.map || {lat: -1, lng: -1})
    const [mapInfo, setMapInfo] = useState<{[key: string]:string} >({})


    const [errorValid1, setErrorValid1] = useState<string>("")
    const [errorValid2, setErrorValid2] = useState<string>("")
    const [errorValid3, setErrorValid3] = useState<string>("")
    const [errorValid4, setErrorValid4] = useState<string>("")
    const [errorValid5, setErrorValid5] = useState<string>("")
    const [errorValid6, setErrorValid6] = useState<string>("")

    //const [goodValid1, setGoodValid1] = useState<string>("")
*/
    const [addEmplPhone, setAddEmplPhone] = React.useState({
        phoneMask: '',
        phone: '',
        numberformat: '1320',
        countyCode: '38'

    });

    const [errorValidPhone, setErrorValidPhone] = useState<string>("")


    const navigate = useNavigate();


    const regNewEmployee = async ( phone: string ) => {
        let id = (phone || "").toString();

        let res: { id: string,  userInfo: IUser, employmentRecord: IEmployment} | null = null;

        if (!id) {
            setGlobalMessage({type: "error", message:"Помилка телефону"} )
            return res;
        }

        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.employment_addEmployee(phone, serviceData._id || "", token);

        //console.log(data)
        if (data.message.error > 0) {
            if (data.message.error == 401) {
                // TODO :remove comment
                //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});

            }
            setGlobalMessage({type: "error", message:data.message.message} )

        } else {
            //updateService(data.message.data)
            res = { id: data.message.data._id,  userInfo: data.message.userInfo, employmentRecord: data.message.data}



            setGlobalMessage({type: "success", message:data.message.message} )
        }


        setIsLoading(false);

        return res;
    }




    const invite_employee = async ( ) => {
        //console.log('Invite btn');
        //console.log(addEmplPhone);
        if ( ! ValidatePhone() )  return;

        setIsLoading(true);

        let resSrvRegEmployment : { id: string,  userInfo: IUser, employmentRecord: IEmployment} | null = await regNewEmployee( addEmplPhone.countyCode + addEmplPhone.phone );
        //console.log(resSrvRegEmployment)
        //employment_addEmployee
        //return;

        if (resSrvRegEmployment == null )
            return;

        // findOne employee name and info

       //console.log(resSrvRegEmployment)

        //const ranges_comp :  IWorkingSlot[] = [ ...(serviceData.working_hours || [])];

        var newEmpl : IEmployee  = {
            phone: addEmplPhone.countyCode + addEmplPhone.phone,
            nickname: resSrvRegEmployment?.userInfo?.name || "",
            info: resSrvRegEmployment?.userInfo?.info || "",
            work_phone: "",
            employment_id: resSrvRegEmployment?.id,
            employment_info: resSrvRegEmployment.employmentRecord,
            enabled: false,
            allow_others_tasks: false,
            work_shifts: []   //ranges_comp
        }

        var new_employees : IEmployee[] = [ ...(serviceData.employees || []), newEmpl ];
        setServiceData ({
                ...serviceData,
                employees : new_employees
            }
        )

    }

    const ValidatePhone = ( ) => {
        //addEmplPhone
        const a10digitsPhonePattern = /^\d{10}$/;

        if ( ! a10digitsPhonePattern.test (addEmplPhone.phone) ) {
            setErrorValidPhone("Не вірний номер телефону");
            return false;
        }

        let empls = serviceData.employees || [];
        for (var emp of empls) {

            if ( emp.phone == addEmplPhone.countyCode + addEmplPhone.phone ) {
                setErrorValidPhone("Праціника з таким тел. номером вже додано.");
                return false;

            }
        }

        //console.log(addEmplPhone)
        setErrorValidPhone("");
        return true;

    }

    const ValidateInput = async ( key: string ) => {
    }


    const enableEmployee_CB = ( index: number, ch: React.ChangeEvent<HTMLInputElement> | null ) => {
        //console.log('-EEE')
        const employees = [ ...(serviceData.employees || []) ];
        employees[index].enabled = ch?.target.checked as never

        setServiceData({
                ...serviceData,
                employees: employees
            }
        )
    }

    const handleAddEmplPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddEmplPhone({
            ...addEmplPhone,
            [event.target.name]: event.target.value,
            phone: event.target.value.replace(/\D/g, '')
        });

        //console.log(addEmplPhone)
    };


    const EmployeeRow_Config = ( { employee, index } : EmployeeProps ) => {
        //console.log("config: " + index);
        //console.log(employee);
        //showConfigDialog, setShowConfigDialog

        setShowConfigDialog(
            {
                ...showConfigDialog,
                open: true,
                index: index
            }
        )
    }



    const EmployeeRow = ( { employee, index } : EmployeeProps ) => {

        let working_hours_week = 40;

        console.log(employee)
        //console.log(employee.employment_info.confirmed)

        return (
            <Grid xs={12} spacing={0} className={employee.enabled ? Styles.block_employee_row : Styles.block_employee_row_disabled}  style={{textAlign:"left", color:'#7E7E7E'}}>
                <Grid container spacing={0} sx={{textAlign:"center"}} >
                    <Grid xs={7.5} >
                        {/*Дані*/}
                        <div>
                            <Grid container spacing={0}  sx={{ textAlign:"left", color: '#fff', fontSize: '12px'}}  alignItems="center">
                                <Grid xs={2}  sx={{ textAlign:"center"}} direction="row" >
                                    <AccountCircle fontSize={"large"}  />
                                </Grid>
                                <Grid xs={10}>
                                    <Grid container spacing={0}>
                                        <Grid xs={8}>
                                            {/*{employee.nickname ? employee.nickname : employee.phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4')}*/}
                                            {employee.nickname ? employee.nickname :  <a href={`tel:${utils.formatPhoneNumber(employee.phone)}`}>{utils.formatPhoneNumber(employee.phone)}</a> }
                                        </Grid>
                                        { employee.employment_info?.confirmed == true ?
                                            <Grid xs={2}  sx={{textAlign:'center'}} >
                                                {/*{employee_data.rating ? employee_data.rating : "---"}*/}
                                                ---
                                            </Grid>
                                            : <></>
                                        }

                                        { employee.employment_info?.confirmed == true ?
                                            <Grid xs={2}  sx={{textAlign:'center'}}>
                                                {working_hours_week}
                                            </Grid>
                                            : <></>
                                        }

                                        { employee.nickname ?
                                            <Grid xs={12} sx={{ color: '#7E7E7E', fontSize: '10px'}}>
                                                {employee.phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4') }
                                            </Grid>
                                            : <></>
                                        }
                                        { employee.employment_info?.confirmed == true ?
                                            <>
                                                <Grid xs={12} sx={{ color: '#7E7E7E', fontSize: '10px'}}>
                                                    Виконано: -- (-- г -- хв)
                                                </Grid>
                                                <Grid xs={12} sx={{ color: '#7E7E7E', fontSize: '10px'}}>
                                                    В черзі: -- (-- г -- хв)
                                                </Grid>
                                            </>
                                            : <></>}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>


                    <Grid xs={4.5}  justifyContent="flex-end" alignItems="center" sx={{display:'inline-flex'}}>
                        {
                            employee.employment_info?.confirmed == null ?
                                <span style={{ width: '200px', marginLeft: '-80px', marginRight: '0', fontSize: '11px', color: '#F6C445'}}>
                                    {/*Employee not answered yet*/}

                                    Працівник має підтвердити запрошення у Профайлі...

                                </span>
                                :
                                <>
                                    {/*Employee answered */}
                                    { employee.employment_info?.confirmed == true ?
                                        <>
                                            {/*Employee approved */}
                                        </>
                                        :
                                        <span style={{ width: '200px', marginLeft: '-80px', marginRight: '0', fontSize: '11px', color: '#D5673B'}}>
                                            {/*Employee rejected */}
                                            Працівник відхилив запрошення.
                                        </span>
                                    }
                                </>
                        }

                        {/*Кнопки*/}
                        <div>
                            {!employee.enabled ? <div>вимкнено</div> : "" }
                            { employee.employment_info?.confirmed == true ?
                            <Switch
                                checked={employee.enabled}
                                onChange={ (v) => enableEmployee_CB(index, v)}
                                color={"success"}
                                size={"small"}
                            />
                                : <></>
                            }

                            { employee.employment_info?.confirmed == true ?
                                <IconButton   color="secondary" aria-label="Статистика працівника" disabled>
                                    <QueryStatsIcon color={"disabled"} fontSize={"medium"}/>
                                </IconButton>
                                : <></>
                            }
                            <IconButton   color="secondary" aria-label="Налаштування" onClick={ ()=> EmployeeRow_Config({employee, index}) }>
                                <SettingsIcon color={"success"} fontSize={"medium"}/>
                            </IconButton>
                        </div>
                    </Grid>

                </Grid>

            </Grid>
        )
    }

    return (

        <div className={Styles.module_block}>

            <Step4_ConfigEmployee
                serviceData={ serviceData }
                setServiceData={setServiceData}
                setShowConfigDialog={setShowConfigDialog}
                setGlobalMessage={setGlobalMessage}
                userInfo={userInfo}
                isOpen={showConfigDialog.open}
                index={showConfigDialog.index}
            />

            <div className={Styles.block} >

                <FormControl
                    variant="standard"
                    fullWidth
                    error={errorValidPhone.length > 0}
                >
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Додати працівника ( моб. телефон)
                    </InputLabel>
                    <Input
                        sx={{fontSize: '20px'}}
                        id="input-phone"

                        value={addEmplPhone.phoneMask}
                        onChange={handleAddEmplPhoneChange}
                        name="phoneMask"
                        placeholder={"(063) 111-1234"}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle fontSize={"large"}/>
                                <span  style={{color: '#F6C445', fontSize: '20px', padding: '0px 0px 0px 10px'}}>+38</span>
                            </InputAdornment>
                        }
                        inputComponent={inputPhoneMask as any}

                        endAdornment={
                            <IconButton   color="secondary" aria-label="add an alarm" onClick={invite_employee}>
                                <AddCircleIcon color={"success"} fontSize={"large"}/>
                            </IconButton>
                        }

                    />
                    {!errorValidPhone ? "" : <div className={Styles.validation_err}><span>{errorValidPhone}</span> </div>}
                </FormControl>


            </div>

            <div className={Styles.Panel_title}>Працівники</div>

            <Grid container spacing={0} style={{color:'#72FA93'}}>
                <Grid xs={8}>
                    <div>
                    <Grid container spacing={0} >
                        <Grid xs={2.5}>
                        </Grid>
                        <Grid xs={5.5}>
                            Ім'я
                        </Grid>
                        <Grid xs={1.8}  sx={{textAlign:'center'}}>
                            Рейт
                        </Grid>
                        <Grid xs={1.7}  sx={{textAlign:'center'}}>
                            г/т
                        </Grid>
                    </Grid>
                    </div>
                </Grid>


                <Grid xs={4} sx={{textAlign:'center'}}>
                    управління
                </Grid>



            </Grid>


            <Grid container spacing={0}>
                {
                    serviceData.employees?.map((employee: IEmployee, index: number )   => (

                            <EmployeeRow key={(employee.phone || "" ).toString()} employee={employee} index={index}/>
                    ))
                }


            </Grid>

            <center>
                <div style={{ paddingBottom: '50px' }}></div>
            </center>

        </div>
    );
};

import Styles from "./Service_Edit.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'

import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'
import {
    AlertColor,
    Divider,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField
} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
    IEmployee, IEmployment,
    IGmap,
    IListedService,
    IService,
    ITime, IWDays,
    iWizardInfo,
    IWorkingSlot
} from "../../interfaces/interfaces";


import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import Grid from "@mui/material/Unstable_Grid2";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import {TimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {API} from "../../services/API";

const utils = new Utils();



interface Errors {

}

//IWorkingSlot

// interface Step2 {
//     url_name: string;
//     service_type: string;
//     created_by: string;
//     name: string;
//     city: string;
//     address: string;
//     phone: string;
//     website: string;
//     map: IGmap,
// }

interface Props{
    serviceData: IService;
    setServiceData: React.Dispatch<React.SetStateAction<IService>>;
    setShowConfigDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    userInfo: IUserInfo;
    isOpen: boolean;
    index: number;


    //handleAdd: (e : React.FormEvent) => void;
}

interface TimeSlotProps {
    slot: IWorkingSlot;
    indexe: number;
}

interface DialogProps {
    open: boolean;
    index: number;
}

export const Step4_ConfigEmployee = ({serviceData, setServiceData, setShowConfigDialog, setGlobalMessage, userInfo, isOpen, index} : Props) => {



    var [inputNickname, setInputNickname] = useState<string>((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].nickname : "")
    var [inputInfo, setInputInfo] = useState<string>((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].info : "")

    const [inputCBAllowOthers, setInputCBAllowOthers] = useState<boolean>((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].allow_others_tasks : true)

    const [validateErrors, setValidateErrors] = useState<number[]>()


    const navigate = useNavigate();



    const handleDialogClose = ( ) => {
        setShowConfigDialog(
            {
                open: false,
                index: 0
            }
        )
    }

    const employee_remove_btn = async ( index : number ) => {

        var new_employees : IEmployee[] = [ ...(serviceData.employees || []) ];

        const empl_phone = new_employees[index].phone;
        const empl_employment_id = new_employees[index].employment_id;
        //console.log(empl_phone)
        //console.log(empl_employment_id)
        //console.log(serviceData._id)

        let token = `${userInfo.authToken};;${userInfo.authDate}`;


        // check employment table
        const data_employment_  = await API.employment_checkEmployment(empl_employment_id || "", token);
        let data_employment: IEmployment = data_employment_.message.data

        console.log(data_employment)

        let checkBooking = true;
        let removeEmployment = false;
        let endEmployment = false;
        if (!data_employment || data_employment.confirmed == null) {
            // can be removed + remove employment record
            checkBooking = false;
            removeEmployment = true;
        }else if (data_employment.confirmed == false) {
            // can be removed // on server on false set date_end = now
            checkBooking = false;
        } else if (data_employment.confirmed == true) {
            // check bookings + employment record set data_end = now // on server set  date_started = now
            checkBooking = true;
            endEmployment = true;
        }

        // check if no bookings
        if (checkBooking) {
            let data_bookings = await API.bookings_checkEmployee(serviceData._id || "", empl_phone, token);
            if (data_bookings.message.data.length > 0) {
                setGlobalMessage({
                    type: "error",
                    message: "У цього працівника ще є заплановані записи, скасуйте або перенесіть їх щоб видалити працівника"
                })
                return;
            }
        }


        if (removeEmployment || endEmployment) {
            //remove || update employment record
            const del_employment_  = await API.employment_remove(empl_employment_id || "", token);
        }

        new_employees.splice(index, 1)

        setServiceData ({
                ...serviceData,
                employees : new_employees
            }
        )

        handleDialogClose();
    }


    const ValidateName = async ( ) => {
    }

    const ValidateInput = async ( key: string ) => {
    }


    const empl_Upd_data = ( index: number, txt: string | number | boolean | null, key: string ) => {

        if (!serviceData.employees || serviceData.employees.length < index ) return;
        var new_empls: IEmployee[] = [...serviceData.employees];
        try {
            new_empls[index][key as keyof IEmployee] = txt as never;
            setServiceData ({
                    ...serviceData,
                employees : new_empls
                }
            )
        } catch (e) {

        }
    }
/*
    const empl_Upd_dataCompetence = ( index: number, txt: string | number | boolean | null, keyc: string ) => {

        //employees[index].work_shifts

        if (!serviceData.employees || serviceData.employees.length < index ) return;
        var new_empls: IEmployee[] = [...serviceData.employees];
        try {
            if ( txt == false &&  new_empls[index].competences.includes(keyc)) {
                //remove
                //console.log('--remove--', index, keyc)
                new_empls[index].competences.splice(new_empls[index].competences.indexOf(keyc),1);
            } else if ( txt == true &&  !new_empls[index].competences.includes(keyc)) {
                //add
                new_empls[index].competences.push(keyc);
            }

            //new_empls[index].competences[keyc] = txt as never;
            setServiceData ({
                    ...serviceData,
                    employees : new_empls
                }
            )
        } catch (e) {

        }
    }
*/
    const ValidateTimeRanges =  ( ) => {


        var employees = [ ...(serviceData.employees || []) ]

        if ( !serviceData || employees.length == 0 || !employees[index] ) return [];

        const ranges_comp :  IWorkingSlot[] = [ ...(serviceData.working_hours || [])];
        const ranges_empl :  IWorkingSlot[] = [ ...(employees[index].work_shifts || []) ];


        var overlappingIndices : number[] = [];
        for ( var i in ranges_empl) {
            for (var j in ranges_empl) {
                if ( i != j) {

                    let r1_start = ranges_empl[i].start.h * 60 +  ranges_empl[i].start.m;
                    let r1_end =  ranges_empl[i].end.h * 60 + ranges_empl[i].end.m;

                    let r2_start = ranges_empl[j].start.h * 60 + ranges_empl[j].start.m;
                    let r2_end =  ranges_empl[j].end.h * 60 + ranges_empl[j].end.m;

                    if ( (r1_start < r2_start && r2_start < r1_end ) ||  (r1_start < r2_end && r2_end < r1_end ) || (r1_start == r2_start)) {
                        // check if wdays are same
                        for ( var k of Object.keys(ranges_empl[i].wdays) ) {
                            if (ranges_empl[i].wdays[k as keyof unknown] && ranges_empl[i].wdays[k as keyof unknown] == ranges_empl[j].wdays[k as keyof unknown] ) {
                                overlappingIndices.push(Number(j))
                                break;
                            }
                        }
                    }
                }
            }
        }
        setValidateErrors(overlappingIndices)
        return overlappingIndices;
    }
    const timeSlot_Add = () => {
        //console.log("add");

        var newSlot : IWorkingSlot  = {
            start: {h: 9, m: 0},
            end: {h: 18, m: 0},
            wdays:{1: true, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false},
            competences: []
        }

        var employees = [ ...(serviceData.employees || []) ]

        employees[index].work_shifts = [ ...(employees[index].work_shifts || []), newSlot ]


        setServiceData ({
                ...serviceData,
            employees : employees
            }
        )

        ValidateTimeRanges()

    }

    const timeSlot_Del = async ( indexw: number ) => {
        //console.log('del ' + index)

        var employees = [ ...(serviceData.employees || []) ]

        //console.log(employees.length, index);
        if ( index < employees.length) {
            employees[index].work_shifts.splice(indexw, 1)

            //console.log(employees[index].work_shifts);
            //var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
            //new_working_hours.splice(index, 1)


            //console.log(new_working_hours)

            setServiceData({
                    ...serviceData,
                    employees: employees
                }
            )
        }
        //ValidateTimeRanges();

    }


    const timeSlot_Upd = ( indexe: number, dt: dayjs.Dayjs |  null, name: string ) => {
        if (dt != null) {


            const employees = [ ...(serviceData.employees || []) ];

            const nm = name.split(".");

            try {
                var val;
                if (nm[1] == "h") val = dt.hour();
                if (nm[1] == "m") val = dt.minute();
                employees[index].work_shifts[indexe][nm[0] as keyof IWorkingSlot][nm[1] as keyof (ITime | IWDays)] = val as never;

                setServiceData ({
                        ...serviceData,
                        employees : employees
                    }
                )
            } catch (e) {

            }

        }
    }
    const timeSlot_UpdCB = ( indexe: number, ch: React.ChangeEvent<HTMLInputElement> | null, key: string, timeSlot_UpdCB_task:string = "" ) => {
        if (ch != null) {

            const employees = [ ...(serviceData.employees || []) ];

            if (key == "wdays") {

                //var new_working_hours : IWorkingSlot[] = [ ...(serviceData.working_hours || []) ];
                var day_num = ch.target.name;

                try {
                    employees[index].work_shifts[indexe][key as keyof IWorkingSlot][day_num as keyof (ITime | IWDays)] = ch.target.checked as never;
                    //new_working_hours[index][key as keyof IWorkingSlot][day_num as keyof (ITime | IWDays)] = ch.target.checked as never;
                    setServiceData({
                            ...serviceData,
                            employees: employees
                        }
                    )
                } catch (e) {

                }
            }

            if (key == "competences") {
                //employees[index].work_shifts
                //if (!serviceData.employees || serviceData.employees.length < index ) return;


                try {
                    if ( ch.target.checked == false &&  employees[index].work_shifts[indexe].competences.includes(timeSlot_UpdCB_task)) {
                        //remove
                        //console.log('--remove--', index, keyc)
                        employees[index].work_shifts[indexe].competences.splice(employees[index].work_shifts[indexe].competences.indexOf(timeSlot_UpdCB_task),1);
                    } else if ( ch.target.checked == true &&  !employees[index].work_shifts[indexe].competences.includes(timeSlot_UpdCB_task)) {
                        //add
                        employees[index].work_shifts[indexe].competences.push(timeSlot_UpdCB_task);
                    }

                    //new_empls[index].competences[keyc] = txt as never;
                    setServiceData ({
                            ...serviceData,
                            employees : employees
                        }
                    )
                } catch (e) {

                }
            }
            /*
            console.log(index)
            console.log(key)
            console.log(ch.target.checked)
            console.log(ch.target.name)

             */
        }
    }

    useEffect(  () => {
        ValidateTimeRanges()
    }, [serviceData])

    useEffect(  () => {
        if ( isOpen == true) {

            setInputNickname((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].nickname : "")
            setInputInfo((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].info : "");
            setInputCBAllowOthers((serviceData.employees && serviceData.employees[index] ) ?  serviceData.employees[index].allow_others_tasks : true);
            //setValidateErrors();


        }
    }, [isOpen])


    const TimeSlotUi = ( { slot, indexe } : TimeSlotProps ) => {


        var isError = validateErrors?.includes(indexe);
        var errorBorder = "0 px";
        if (isError) errorBorder = '1px solid #ff0000'

        return (
            <Grid xs={12} spacing={0} className={Styles.block}  style={{textAlign:"left", color:'#7E7E7E', border: errorBorder}}>
                <div>
                    <div style={{textAlign:'right', lineHeight: '1px', color: '#E45F2B', margin:'-20px 3px'}}>
                        <IconButton
                            edge="end"
                            color="error"
                            onClick={()=>timeSlot_Del(indexe)}
                            aria-label="close"
                        >
                            <DeleteIcon style={{paddingTop:'5px'}} fontSize={"small"}/>
                        </IconButton>
                    </div>


                    <div className={Styles.block_title}>Послуги</div>
                    <div className={Styles.block2} >
                        { serviceData.list_of_services?.map( (row_srv) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={row_srv.sid}
                                        checked={slot.competences.includes(row_srv.sid)}
                                        //onChange={ (v) =>  empl_Upd_dataCompetence(index, v.target.checked, row_srv.sid)}
                                        onChange={ (v) =>  timeSlot_UpdCB(indexe, v, "competences", row_srv.sid)}
                                        color={"success"}
                                        name={row_srv.sid}
                                        size={"small"}
                                        style={{paddingRight:'2px', paddingLeft:'5px', paddingTop:'0px', paddingBottom:'0px'}}
                                    />
                                }
                                label={row_srv.name}
                                key={row_srv.sid}
                            />
                        ))}
                    </div>

                    <div style={{ paddingBottom: '5px' }}></div>
                    <Divider light/>
                    <div style={{ paddingBottom: '5px' }}></div>
                    <Grid container spacing={0} >
                        <Grid  xs={5.5}>
                            {/* Left div */}
                            <div>
                                Початок зміни
                                <div style={{paddingTop: '10px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={5.5}>
                                            {/* Lh div */}
                                            <div>
                                                <TimePicker
                                                    views={['hours']}
                                                    format="HH"
                                                    label="год"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.start.h}:${slot.start.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(indexe, v, "start.h")}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid  xs={1}>
                                            {/* : div */}
                                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '11px', fontSize:'16px', fontWeight:'bold' }}>
                                                <span>:</span>
                                            </div>
                                        </Grid>
                                        <Grid  xs={5.5}>
                                            {/* Rh div */}
                                            <div >
                                                <TimePicker
                                                    views={['minutes']}
                                                    format="mm"
                                                    label="хв"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.start.h}:${slot.start.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(indexe, v, "start.m")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid  xs={1}>
                            {/* Middle div */}
                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '40px', fontSize:'16px', fontWeight:'bold' }}>
                                <span>-</span>
                            </div>
                        </Grid>
                        <Grid  xs={5.5}>
                            {/* Right div */}
                            <div >
                                Кінець зміни
                                <div style={{paddingTop: '10px'}}>
                                    <Grid container spacing={0} >
                                        <Grid  xs={5.5}>
                                            {/* Lh div */}
                                            <div>
                                                <TimePicker
                                                    views={['hours']}
                                                    format="HH"
                                                    label="год"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.end.h}:${slot.end.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(indexe, v, "end.h")}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid  xs={1}>
                                            {/* : div */}
                                            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '11px', fontSize:'16px', fontWeight:'bold' }}>
                                                <span>:</span>
                                            </div>
                                        </Grid>
                                        <Grid  xs={5.5}>
                                            {/* Rh div */}
                                            <div >
                                                <TimePicker
                                                    views={['minutes']}
                                                    format="mm"
                                                    label="хв"
                                                    ampm={false}
                                                    className={Styles.timePicker}
                                                    value={dayjs(`2000-0-0T${slot.end.h}:${slot.end.m}`)}
                                                    onChange={ (v) => timeSlot_Upd(indexe, v, "end.m")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{marginBottom:'-8px', paddingTop:'5px'}}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["1"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="success" name="1" size={"small"} style={{paddingRight:'0px',}}/>
                            }
                            label="пн"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["2"]}  onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")} color="success" name="2" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="вт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["3"]}  onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")} color="success" name="3" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="ср"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["4"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="success" name="4" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="чт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["5"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="success" name="5" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="пт"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["6"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="secondary" name="6" size={"small"} style={{paddingRight:'0px', paddingLeft:'3px'}}/>
                            }
                            label="сб"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={slot.wdays["7"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="secondary" name="7" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            }
                            label="нд"
                        />
                    </div>
                    { isError ?
                        <div className={Styles.validation_err}><span>Часові відрізки не можуть накладатися</span> </div>
                        :<></>
                    }
                </div>
            </Grid>
        );
    }

    var empl : IEmployee | null = (serviceData.employees && serviceData.employees?.length > 0) ? serviceData.employees[index] : null;

    return (
        <Dialog
            open={isOpen || false}
            onClose={handleDialogClose}

            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth={"xs"}
            fullWidth

        >
            <DialogTitle id="scroll-dialog-title" className={Styles.dialog_block}>
                <IconButton   color="secondary" aria-label="закрити" onClick={handleDialogClose}>
                    <img src={IMG_BTN_BACK} alt="Назад" title="Назад" />
                </IconButton>
                <span className={Styles.titleDialog} >Редагування працівника</span>
            </DialogTitle>


            <DialogContent dividers={true} className={Styles.dialog_block} >
                <div className={Styles.block} >

                    <Grid container spacing={0}>


                        <Grid xs={3} justifyContent="flex-start" alignItems="center" sx={{display:'inline-flex'}}>
                            <AccountCircle sx={{fontSize:"50px"}}  />
                        </Grid>

                        <Grid xs={9}>
                            <TextField
                                fullWidth
                                label="Ім'я"
                                id="step4_ec_nickname"
                                placeholder={"Наприклад: Петро"}
                                variant="outlined"
                                value={inputNickname}
                                style={{color:'#F6C445'}}
                                size={"small"}
                                onChange={(e)=>setInputNickname(utils.clearSymbol(e.target.value))}
                                onBlur={()=>empl_Upd_data(index, inputNickname, "nickname") }
                            />

                            <div style={{ paddingBottom: '5px' }}></div>
                            <TextField
                                fullWidth

                                label="Опис"
                                id="step4_ec_info"
                                placeholder={"Наприклад: Майстер 1-категоії"}
                                variant="outlined"
                                value={inputInfo}
                                style={{color:'#F6C445'}}
                                size={"small"}
                                onChange={(e)=>setInputInfo(utils.clearSymbol(e.target.value))}
                                onBlur={()=>empl_Upd_data(index, inputInfo, "info") }
                            />


                        </Grid>
                    </Grid>
                </div>

                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Checkbox*/}
                {/*            checked={inputCBAllowOthers}*/}
                {/*            onChange={ (v) => {setInputCBAllowOthers(v.target.checked); empl_Upd_data(index, v.target.checked, "allow_others_tasks")} }*/}
                {/*            color={"success"}*/}
                {/*            name="allow_others_tasks"*/}
                {/*            size={"small"}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label={"Дозволити бачити завдання інших"}*/}
                {/*/>*/}



                <div className={Styles.Panel_title}>Робочі зміни <Button variant={"contained"} color="success" onClick={timeSlot_Add}>[+] Додати </Button></div>
                {
                    empl?.work_shifts?.map((row: IWorkingSlot, indexe: number ) => (
                        <TimeSlotUi slot={row} key={indexe} indexe={indexe}/>
                    ))
                }

                <center>
                    <Button color="error" onClick={()=>employee_remove_btn(index)}>[-] Вилучити працівника</Button>
                </center>

            </DialogContent>

        </Dialog>

    );
};

import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./User_Profile.module.scss";
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {API} from "../../services/API";
import {FooterLinks_MainMenu} from "../footer/FooterLinks_MainMenu";
import Grid from "@mui/material/Unstable_Grid2";
import AccountCircle from "@mui/icons-material/AccountCircle";
import axios from "axios";

const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth: React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo: React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage: React.Dispatch<React.SetStateAction<{ type: AlertColor, message: String }>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const User_Profile_AvaName = ({
                                         userAuth,
                                         setUserAuth,
                                         userInfo,
                                         setUserInfo,
                                         footer,
                                         setFooter,
                                         setGlobalMessage,
                                         setIsLoading
                                     }: Props) => {
    const navigate = useNavigate();
    //const [inputDomain, setInputDomain] = useState<string>("")

    const [inputName, setInputName] = useState<string>(userInfo.name || "" );
    const [inputInfo, setInputInfo] = useState<string>(userInfo.info || "" );
    const [inputCity, setInputCity] = useState<string>(userInfo.city || "" );

    const [inputLastName, setInputLastName] = useState<string>(userInfo.name || "" );
    const [inputLastInfo, setInputLastInfo] = useState<string>(userInfo.info || "" );
    const [inputLastCity, setInputLastCity] = useState<string>(userInfo.city || "" );

    const [errorValid2, setErrorValid2] = useState<string>("")

    const handleBack = () => {
        navigate(-1)
    };
    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
        //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
        // let req_res = await API.service_checkName(inputDomain)
    }

    const updateTextField = async (key: string) => {
        const newUserInfo = {...userInfo};

        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data = null;

        if (key == "name" && inputLastName != inputName) {
            newUserInfo.name = utils.clearSymbol(inputName);
            data  = await API.profile_updateName(newUserInfo.name, token);
            setInputLastName(inputName);
        }
        if (key == "info" && inputLastInfo != inputInfo) {
            newUserInfo.info = utils.clearSymbol(inputInfo);
            data  = await API.profile_updateInfo(newUserInfo.info, token);
            setInputLastInfo(inputInfo);
        }
        if (key == "city" && inputLastCity != inputCity) {
            newUserInfo.city = utils.clearSymbol(inputCity);
            data  = await API.profile_updateCity(newUserInfo.city, token);
            setInputLastCity(inputCity);
        }

        if (data) {
            if (data.message?.error > 0) {
                if (data.message.error == 401) {
                    // TODO :remove comment
                    //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
                }
                setGlobalMessage({type: "error", message: data.message.message})
            } else {
                setUserInfo(newUserInfo);
                setGlobalMessage({type: "success", message: data.message.message})
            }
        }


    }

    const getCity = ( city_type : string) => {
        for (var c of CONFIG.CITIES) {
            if (c.value == city_type)    return c.label
        }
        return ""
    }




    const FileUploadForm = () => {
        const [selectedFile, setSelectedFile] = useState(null);

        const handleFileChange = (event :any) => {
            setSelectedFile(event.target.files[0]);

        };

        const handleFileUpload = async () => {
            if (!selectedFile) {
                alert('Please select a file');
                return;
            }

            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                const response = await API.uploads_avatar(formData, "")
                console.log(response);
                // Handle success (e.g., show a success message to the user)
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        };

        return (
            <div>
                <h2>File Upload Form</h2>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleFileUpload}>Upload</button>
            </div>
        );
    };





    useEffect(() => {
        console.log(userInfo)
        //setFooter(FooterLinks_MainMenu(["home", "bookings", "services", "profile", "clients"]))
    }, [])

    return (
        <div className={Styles.block}>
            <Grid container spacing={0}>

                <Grid xs={2} justifyContent="flex-start" alignItems="center" sx={{display: 'inline-flex'}}>
                    <div>
                        <AccountCircle sx={{fontSize: "50px"}}/>
                        заміна аватара
                    </div>
                </Grid>

                <Grid xs={10}>
                    <TextField
                        fullWidth
                        label="Ім'я"
                        id="step4_ec_nickname"
                        placeholder={"Наприклад: Петро"}
                        variant="outlined"
                        value={inputName}
                        style={{color: '#F6C445'}}
                        size={"small"}
                        onChange={(e)=>setInputName(utils.clearSymbol(e.target.value))}
                        onBlur={()=>updateTextField("name") }
                        //onBlur={()=>empl_Upd_data(index, inputNickname, "nickname") }
                    />

                    <div style={{paddingBottom: '10px'}}></div>
                    <TextField
                        fullWidth

                        label="Опис"
                        id="step4_ec_info"
                        placeholder={"Наприклад: Майстер 1-категоії"}
                        variant="outlined"
                        value={inputInfo}
                        style={{color: '#F6C445'}}
                        size={"small"}
                        onChange={(e)=>setInputInfo(utils.clearSymbol(e.target.value))}
                        onBlur={()=>updateTextField("info") }
                    />

                    <div style={{paddingBottom: '5px'}}></div>

                    <div className={Styles.input_group}>
                        <FormControl fullWidth variant="outlined"  sx={{ m: 0, minWidth: 120 }} size="small" >
                            <InputLabel id="demo-simple-select-label">Місто</InputLabel>
                            <Select
                                style={{borderRadius: '5px 5px 5px 5px', textAlign: "right", color: '#F6C445'}}
                                labelId="step1_city"
                                id="step1_city"
                                label="Місто"
                                variant="outlined"
                                value={inputCity}
                                onChange={(e)=>setInputCity(e.target.value) }
                                error={errorValid2.length > 0}
                                onBlur={()=>updateTextField('city')}


                            >
                                {CONFIG.CITIES.map((row) => (
                                    <MenuItem
                                        key={row.value}
                                        value={row.value}

                                    >
                                        {row.label}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        {
                            !errorValid2 ? "" : <div className={Styles.validation_err}><span>{errorValid2}</span> </div>
                            //<div className={Styles.validation_ok}><span>Добре</span> </div>
                        }
                    </div>
                    <div style={{paddingBottom: '5px'}}></div>
                    <div>
                        тел.: {utils.formatPhoneNumber(userInfo.phone)}
                    </div>



                </Grid>
            </Grid>

        </div>
    );
}
export default {


    //API: "http://127.0.0.1:6060",
    API: "https://api.zapys.vip",

    GMAP_KEY: "AIzaSyAh22GR4FuMOu8YapM3I7QzOH0jGK4Xyls",
    GMAP_API: 'https://maps.googleapis.com/maps/api/geocode/json',

    //AUTH_FRAME: "http://localhost:3000/afrm.html",
    AUTH_FRAME: "https://dev.zapys.vip/afrm.html",

    DOMAINS_1LEVEL: ["dev", "zapys", "localhost"],
    MAIN_DOMAIN: "dev.zapys.vip",

    SERVICE_TYPES: [
        { value: 'psychologist', label: 'Психолог' },
        { value: 'barber', label: 'Перукарні та Барбер шопи' },
        { value: 'beauty', label: 'Салон краси' },
        { value: 'nails', label: 'Манікюр/Педікюр' },
        { value: 'beautician', label: 'Косметолог' },
        { value: 'Massage', label: 'Масаж' },
        { value: 'rehabilitation', label: 'Реабілітація' },
        { value: 'advice', label: 'Консультація' },
        { value: 'dentistry', label: 'Стоматологія' },
        { value: 'ultrasound', label: 'УЗД' },
        { value: 'tire_fitting', label: 'Шиномонтаж' },
        { value: 'car_service_station', label: 'СТО' },
        { value: 'master_classes', label: 'Майстеркласи' },
        { value: 'teaching', label: 'Навчання' },
        { value: 'cleaning', label: 'Клінінг' }
    ],

    CITIES: [
        { value: 'lviv', label: 'Львів' },
        { value: 'kyiv', label: 'Київ' }
    ]


}
import React, {useState, useEffect} from 'react';
import Styles from "./Login.module.scss";
//import { IoCalendarSharp } from 'react-icons/io5';
import {IUserInfo} from "../../models/user";
import {Utils} from "../../helpers/utils";
import Auth from "../../services/auth";
import Button from '@mui/material/Button';
const utils = new Utils();



interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo,
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>
    //handleAdd: (e : React.FormEvent) => void;
}


export const Logout = ({userAuth, setUserAuth, userInfo, setUserInfo} : Props) => {

    const logout_btn = async () => {
        Auth.DoLogout({setUserAuth, userInfo, setUserInfo})
    }


    return (
        <div className={Styles.logoutDiv}>

                {/*<p>Вас Авторизовано!</p>*/}
                {/*<div>Сервіс знаходиться у розробці.</div>*/}
                {/*<div>Ми сповістимо Вас коли сервіс запрацює...</div>*/}
                {/*<p></p>*/}
                <Button onClick={logout_btn} color={"error"} variant={"contained"}>
                    Вилогінитися
                </Button>


        </div>
    );
};
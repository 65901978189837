import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./User_Profile.module.scss";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, Divider, IconButton} from "@mui/material";
import {API} from "../../services/API";
import {FooterLinks_MainMenu} from "../footer/FooterLinks_MainMenu";
import Grid from "@mui/material/Unstable_Grid2";
import {IEmployment} from "../../interfaces/interfaces";
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import IMG_BTN_SHARE from "../../images/ui/service_btn_share.png";


const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const User_Profile_Employments = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    //const [inputDomain, setInputDomain] = useState<string>("")

    const [employments, setEmployments] = useState<IEmployment[]>([])

    const handleBack = ( ) => {
        navigate(-1)
    };


    const handleEmploymentApprove = async ( employment_id : string) => {
        //console.log("approve: ", employment_id);
        setIsLoading(true);
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.employment_approve(employment_id, token);
        fetchData();
        //console.log(data)
        //employment_approve
        //setIsLoading(false);

    };
    const handleEmploymentReject = async (employment_id : string ) => {
        setIsLoading(true);
        //console.log("reject: ", employment_id)
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.employment_reject(employment_id, token);
        //console.log(data)
        fetchData();
        //setIsLoading(false);
    };


    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
        //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
        // let req_res = await API.service_checkName(inputDomain)
    }

    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st
        }
        return {label: "", value : ""};
    }

    const getCity = ( city_type : string) => {
        for (var c of CONFIG.CITIES) {
            if (c.value == city_type)    return c.label
        }
        return ""
    }

    const fetchData = async () => {

        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.employment_getEmployments(token);
        //serviceData
        //console.log(data.message.data.list_of_services)
        //console.log(data.message.data)

        if (data.message.error == 0) {
            console.log(data.message.data)
            setEmployments(data.message.data)
        }

        setIsLoading(false);
    }




    useEffect( () => {
        setIsLoading(true);
        fetchData();

    }, [])

    return (
        <>
            { employments.length ?

                <div className={Styles.block}>
                    <div className={Styles.blockTitle}>Співпраця</div>
                    <div style={{paddingBottom:'10px'}}></div>

                        {/*// [].service.activated ??*/}
                        {/*// [].service.name*/}
                        {/*// [].service.phone*/}
                        {/*// [].service.service_type*/}
                        {/*// [].service.city*/}
                        {/*// [].service.address*/}
                        {/*// [].service.employees[].work_shifts*/}

                        { employments.map( ( row ) => (
                            <Grid container spacing={0} key={row._id}>
                                <Grid md={9} xs={8.5} >
                                    <div className={Styles.serviceType}>{getServiceType( row.service?.service_type || "").label}</div>
                                    <div className={Styles.serviceName}><strong>{row.service?.name || ""}</strong></div>
                                    <div className={Styles.serviceAddress}>{getCity(row.service?.city || "")}, {row.service?.address || ""}</div>
                                    {row.service?.phone ?
                                        <div className={Styles.servicePhone}> <a href={`tel:${row.service?.phone}`}>{utils.formatPhoneNumber( row.service?.phone)}</a></div>
                                        :
                                        <></>
                                    }
                                </Grid>


                                <Grid md={3} xs={3.5} justifyContent="flex-start" alignItems="center" sx={{display: 'inline-flex'}}>

                                    {/*<IconButton onClick={(e)=>handleEmploymentReject( (row._id || "").toString() )} color={"success"} aria-label="Список завдань">*/}
                                    {/*    <ListAltIcon fontSize={"large"}/>*/}
                                    {/*</IconButton>*/}

                                    <IconButton onClick={()=>navigate(`/bookingsEmployee?service_id=${row.service?._id}`)} color="success" aria-label="Список завдань">
                                        <ListAltIcon fontSize={"large"}/>
                                    </IconButton>



                                    <IconButton onClick={()=>navigate(`/services/share/${row.service_id}`)}  color="secondary" aria-label="Інфо для Запису" size="small">
                                        <img src={IMG_BTN_SHARE} alt="Інфо для Запису" title="Інфо для Запису" />
                                    </IconButton>

                                    {/*<Button color={"error"} size={"small"} variant={"contained"}>Відхилити</Button> &nbsp;*/}
                                    {/*<Button color={"success"} size={"small"} variant={"contained"}>Прийняти</Button>*/}
                                </Grid>

                                <Grid xs={12} style={{paddingTop: '10px', paddingBottom: '5px'}}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        ) )}




                </div>
                :<></>
            }
        </>
    );
}
import Styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'
export const Header = () => {
  return (
    <header className={Styles.header}>

      <Link to={"/"}>

          <h1>
              <img src={Logo} alt="logo" /> ZAPYS.VIP</h1>

      </Link>
        <span className={Styles.header_text2}>ЗАПИС ОНЛАЙН</span>
    </header>


  );
};

import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./Service_list.module.scss";
import {useNavigate, useParams} from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';

// import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_PAUSE from '../../images/ui/service_btn_pause.png'
import IMG_BTN_PLAY from '../../images/ui/service_btn_play.png'
import IMG_BTN_STATS from '../../images/ui/service_btn_stats.png'
import IMG_BTN_SHARE from '../../images/ui/service_btn_share.png'
import IMG_BTN_CONFIG from '../../images/ui/service_btn_config.png'



import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {AlertColor, IconButton, Paper, styled} from "@mui/material";
import {API} from "../../services/API";
import Grid from "@mui/material/Unstable_Grid2";
import {IService} from "../../interfaces/interfaces";
import {type} from "os";

const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface itemData { key: string; row: IService; }

export const Service_List_Public_Demo = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    const [userServices, setUserServices] = useState<IService[]>([])

    const handleBack = ( ) => {
        //navigate(-1)
    };
    const handleNext = async () => {
        //setGlobalMessage({type: "success", message: "Успішна реєстрація"} )
        //Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
        // let req_res = await API.service_checkName(inputDomain)
    }

    useEffect(  () => {
        const fetchData = async () => {
            //console.log(userInfo)
            let data  = await API.service_listPublicDemo();

            if (data.message.error > 0) {
                setGlobalMessage({type: "error", message:data.message.message} )
            } else {
                setUserServices( data.message.data )
                //console.log( data.message )
            }
            setIsLoading(false);

        }
        setIsLoading(true);
        fetchData();
    }, [])



    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st
        }
        return {label: "", value : ""};
    }


    const Item = ( { row } : itemData ) => {
        //console.log(row)

        //row.service_type
        //row.url_name
        //row._id

        let name = row.name || row.url_name || "";
        if (name.length > 32) name = name.slice(0, 32) + '..';

        let service_type_obj = getServiceType(row.service_type || "")

        let address = row.address || "Адресу не вказано"

        return  (
            <Grid className={Styles.gridRow} xs={12} container spacing={1}>
                <Grid xs={10} spacing={0}>
                    <div onClick={()=>navigate(`/s/${row.url_name}`)}>
                        <div className={Styles.title_type}>{service_type_obj.label}</div>
                        <div className={Styles.title_name}><a href={`/s/${row.url_name}`}>{name}</a> </div>
                        <div className={Styles.title_address}>{address}</div>
                        <div className={Styles.title_type}>
                            <a href={`tel: ${utils.formatPhoneNumber(row.phone??"")}`}>{utils.formatPhoneNumber(row.phone??"")}</a>
                        </div>
                    </div>
                </Grid>
                <Grid xs={2} className={Styles.buttons_grp}>


                    <IconButton onClick={()=>navigate(`/services/share/${row._id}`)}  color="secondary" aria-label="Інфо для клієнтів" size="small">
                        <img src={IMG_BTN_SHARE} alt="Інфо для клієнтів" title="Інфо для клієнтів" />
                    </IconButton>


                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <center><h1>Доступні послуги</h1></center>
            <Grid container spacing={1}>
                {
                    userServices.map((row) => (
                        <Item key={(row.url_name || "" ).toString()} row={row}/>


                    ))
                }

            </Grid>
        </div>
    );
}
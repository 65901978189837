import Styles from "./Service_Edit.module.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import { IoCalendarSharp } from 'react-icons/io5';
import  Logo  from '../../logo.svg'
import React, {FunctionComponent, useEffect, useState} from "react";
import Select from 'react-select'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import IMG_btn_next_arrow from '../../images/ui/btn_next_arrow.png'
import {AlertColor, Box, IconButton, Typography} from "@mui/material";

import {IService, iWizardInfo} from "../../interfaces/interfaces";

import {Step1} from "./Step1";
import {Step2} from "./Step2";
import {API} from "../../services/API";
import Auth from "../../services/auth";
import {Step3} from "./Step3";
import {Step4} from "./Step4";

interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    //handleAdd: (e : React.FormEvent) => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`serviceConfig-tabpanel-${index}`}

            aria-labelledby={`serviceConfig-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 0 }} >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `serviceConfig-tab-${index}`,
        'aria-controls': `serviceConfig-tabpanel-${index}`,
    };
}
export const Service_Edit = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {

    const navigate = useNavigate();
    const [doneLoading, setDoneLoading] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = React.useState(0);
    const params = useParams(); // access url params





    const [serviceData, setServiceData] = useState<IService>( {} );


    //var serviceData: IService;
    /*

    var serviceData = {
        url_name: "",
        service_type: "",

        name: "",
        city: "",
        address: "",
        phone: "",
        website: "",
        map: { lat: 0, lng: 0 },

        working_hours: [
            {
                start: {h: 9, m: 0},
                end: {h: 12, m: 0},
                wdays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false}
            },
            {
                start: {h: 13, m: 0},
                end: {h: 18, m: 0},
                wdays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false}
            }
        ],
        services: [
            {
                name: "",
                time: {h: 1, m: 30},
                price: 0,
                info: ""
            }
        ],
        employees: [
            {
                phone: "",
                name: "",
                rating: "",
                task: {
                    assigned: 0,
                    open: 0,
                    closed: 0,
                },
                competences: {},
                work_shift: [
                    {
                        start: {h: 9, m: 0},
                        end: {h: 12, m: 0},
                        wdays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false}
                    },
                    {
                        start: {h: 13, m: 0},
                        end: {h: 18, m: 0},
                        wdays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false}
                    }
                ]
            }

        ]



    };
    */


    const updateService = (update : object) => {
        setServiceData ( {
            ...serviceData,
            ...update,
        })
    }

    const saveServiceData = async () => {
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        //console.log(serviceData.list_of_services)
        let upd_status = await API.service_edit_update(token, serviceData)
        //console.log(upd_status.message.data.list_of_services)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);

        //saveServiceData();
    };

    const handleBack = ( ) => {
        // TODO: validate
        //setWizardInfo({step:1})
        //navigate("/")
        //saveServiceData();
        navigate(-1)
    };



    useEffect(  () => {
        if (doneLoading && serviceData) {
            saveServiceData();
            console.log('-saved-')
        }
    }, [serviceData])

    useEffect(  () => {

        //console.log(); // 👉️ {userId: '4200'}
        setFooter(null)

        const fetchData = async () => {
            let id = (params.id || "").toString();

            if (!id) {
                setGlobalMessage({type: "error", message:"Error: no ID"} )
                return;
            }
            let token = `${userInfo.authToken};;${userInfo.authDate}`;
            let data  = await API.service_edit_getOne(token, id);
            //serviceData
            //console.log(data.message.data.list_of_services)
            //console.log(data.message.data)

            if (data.message.error > 0) {
                if (data.message.error == 401) {
                    // TODO :remove comment
                    Auth.DoLogout({setUserAuth, userInfo, setUserInfo});
                }
                setGlobalMessage({type: "error", message:data.message.message} )

            } else {
                updateService(data.message.data)
                setDoneLoading (true)
            }

            setIsLoading(false);
        }
        setIsLoading(true);
        fetchData();


    }, [])


    return (
        <div>

            <IconButton onClick={handleBack}  color="secondary" aria-label="add an alarm">
                <img src={IMG_BTN_BACK} alt="Назад" title="Назад" />
            </IconButton>



            <span className={Styles.title} >Редагування послуги</span>




            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        aria-label="Config Tabs"
                        textColor="inherit"
                        variant="fullWidth"

                    >
                        <Tab className={Styles.ConfigTab} label="Інфо" {...a11yProps(0)} sx={{minWidth: '20px', color: '#81B545', fontSize: '12px'}} />
                        <Tab  className={Styles.ConfigTab} label="Різне"  {...a11yProps(1)} sx={{minWidth: '20px', color: '#81B545', fontSize: '12px'}} wrapped/>
                        <Tab className={Styles.ConfigTab} label="Послуги" {...a11yProps(2)} sx={{minWidth: '20px', color: '#81B545', fontSize: '12px'}} />
                        <Tab className={Styles.ConfigTab} label="Працівинки" {...a11yProps(3)} sx={{minWidth: '20px', color: '#81B545', fontSize: '12px'}}  />

                    </Tabs>
                </Box>

                { doneLoading ?  <>
                    <CustomTabPanel value={tabIndex} index={0}  >
                        <Step1
                            serviceData={ serviceData }
                            setServiceData={setServiceData}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={1}>
                        <Step2
                            serviceData={ serviceData }
                            setServiceData={setServiceData}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={2}>
                        <Step3
                            serviceData={ serviceData }
                            setServiceData={setServiceData}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={3}>
                        <Step4
                            serviceData={ serviceData }
                            setServiceData={setServiceData}
                            setGlobalMessage={setGlobalMessage}
                            setIsLoading={setIsLoading}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />
                    </CustomTabPanel>
                </> : <> Завантаження...</>
                }
            </Box>

        </div>
  );
};

import React, {FunctionComponent, useEffect, useState} from "react";

import Styles from "./Booking.module.scss";
import {useNavigate, useParams} from 'react-router-dom';

//import Select from 'react-select'

import Button from '@mui/material/Button';

// import EastIcon from '@mui/icons-material/East';

import {IUserInfo} from "../../models/user";
import IMG_BTN_BACK from '../../images/ui/btn_back.png'
import Auth from "../../services/auth";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";
import {
    AlertColor,
    CircularProgress,
    CreateMUIStyled,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import {API} from "../../services/API";
import {Login} from "../Login/Login";
import {dateInterval, IBookingRes, IEmployee, IService, ITime, IWDays, IWorkingSlot} from "../../interfaces/interfaces";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";

import DatePicker, { registerLocale } from 'react-datepicker';
import uk from "date-fns/locale/uk";
import 'react-datepicker/dist/react-datepicker.css'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddHomeIcon from "@mui/icons-material/AddHome";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as domain from "domain";

const utils = new Utils();

interface Props {
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IBookingTime {
    count: number;
    total_time_min: number;
    total_time: ITime;
}

export type ColorTypes =
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning"
    | "default"
    | "inherit"
    | "info";

export const Booking = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {
    const navigate = useNavigate();
    const [serviceData, setServiceData] = useState<IService | null>(null)
    const [inputEmployee, setInputEmployee] = useState<string>("any")
    const [inputTasks, setInputTasks] = useState<{[key: string] : boolean }>({})
    const [dateCalendar, setDateCalendar] = useState<Date>();

    const [bookingTime, setBookingTime] = useState<IBookingTime>({count: 0, total_time_min: 0, total_time: {h: 0, m: 0} });  //in minutes
    const { url_name } = useParams();

    const [availableEmployees, setAvailableEmployees] = useState<{ [key:string]: boolean }>( {});
    const [availableTasks, setAvailableTasks] = useState<{ [key:string]: boolean }>( {});

    const [calendarIntervals, setCalendarIntervals] = useState<dateInterval[]>( []);


    //const [ timeSlotsList, setTimeSlotsList] = useState<{ [key: number]: string }>( { 900: "9:00", 930: "9:30", 1000: "10:00", 1030: "10:30", 1100: "11:00", 1130: "11:30"} )
    const [ timeSlotsList, setTimeSlotsList] = useState<{ [key: number]: string }>( { } )
    const [ selectedTimeSlot, setSelectedTimeSlot] = useState<number>( -1)

    //const [doneBookingData, setDoneBookingData] = useState<{[key: string] : string | string[] | ITime | number | Date | undefined }>({})
    const [doneBookingData, setDoneBookingData] = useState<{employee : string,  tasks: string[], duration: string, date: string, startTime: string } | null>(null)

    const [refreshBookings, setRefreshBookings] = useState<number>(0)

    const [lastCheckedTasks, setLastCheckedTasks] = useState<{ sid: string, lastBookingTime: IBookingTime}>()


    const handleBackHome = ( ) => {
        navigate("/");
    };

    const handleCalendarClick = ( date: Date | undefined) => {
        console.log('cick')
        console.log(date)
        console.log(dateCalendar)
        if ( date?.getDate() == dateCalendar?.getDate()) {
            //console.log('same date click');
            setDateCalendar(undefined)
        } else {
            setDateCalendar(date )
        }
    };

    const handleBookMore = ( ) => {
        //setInputTasks({});
        setSelectedTimeSlot(-1);
        //setDateCalendar( undefined )
        setDoneBookingData(null);
        //setInputTasks({});
        //setInputEmployee("any")
        setRefreshBookings(refreshBookings + 1);

    };

    const setInputCBTasks = (checked: boolean, sid: string) => {
        //inputTasks, setInputTasks
        const newInputTasks = {...inputTasks, [sid]: checked}
        setInputTasks(newInputTasks);
    }

    const timeSlotClick = (time_key: string) => {
        //console.log(time_key)
        //selectedTimeSlot, setSelectedTimeSlot
        if (bookingTime.total_time_min == 0) {
            setGlobalMessage({type: "error", message: "Виберіть послугу(и)"})
            return;
        }

        if (selectedTimeSlot == Number(time_key)) {
            setSelectedTimeSlot(-1);
        } else {
            setSelectedTimeSlot(Number(time_key));
        }
    };


    const processBookingInfo = ( data : IBookingRes) => {

        setAvailableEmployees(data.availableEmployees);
        setAvailableTasks(data.availableTasks);
        const calendarIntervals : dateInterval[]  = data.calendarIntervals;

        //console.log(calendarIntervals)
        //console.log(typeof calendarIntervals[0].start)
        setBookingTime(data.bookingTime);
        for ( var k in calendarIntervals) {
            calendarIntervals[k].start = new Date(calendarIntervals[k].start);
            calendarIntervals[k].end = new Date(calendarIntervals[k].end);
        }
        //console.log(typeof calendarIntervals[0].start)

        setCalendarIntervals(calendarIntervals);
        setTimeSlotsList(data.availableTimeSlots);

        //TODO: AUTOSTART
        //if (!dateCalendar && calendarIntervals.length > 0 && data.bookingTime.count > 0) {
        //    setDateCalendar( calendarIntervals[0].start );
        //}

        //console.log(data.availableTimeSlots)
        if (selectedTimeSlot && !data.availableTimeSlots[selectedTimeSlot])
            setSelectedTimeSlot(-1)
        //selectedTimeSlot, setSelectedTimeSlot
    }


    const btnBookSlot = async () => {
        console.log('book');
        //booking_bookSlot

        //if (userInfo.)

        if ( userAuth != true ) {
            setGlobalMessage({type: "error", message: "Авторизуйтеся будь ласка, для того, щоб зробити запис"})
            return;
        }


        setIsLoading(true);
        let token = `${userInfo.authToken};;${userInfo.authDate}`;
        let data  = await API.booking_bookSlot(serviceData?._id || "", inputEmployee, inputTasks, dateCalendar, selectedTimeSlot, token);

        setIsLoading(false);


        if (data.message.error > 0) {
            setGlobalMessage({type: "error", message: data.message.message})

            //setSelectedTimeSlot(-1);
            //setDoneBookingData(null);
            setRefreshBookings(refreshBookings + 1);
            return;
        }



        let tasks : string[] = data.message?.data?.tasks || [];

        var task_labels : string [] = [];
        for (var t of tasks) {
            let label = (serviceData?.list_of_services?.find( (row_srv) => row_srv.sid === t))?.name || "";
            if (label)
                task_labels.push(label)
        }

        var bookingTime : ITime = data.message?.data?.bookingTime || {h: 0, m: 0};

        let empl_name = (serviceData?.employees?.find( ( row ) =>  row.phone === data?.message?.data?.employee ))?.nickname || "";
        let duration = utils.iTime2str(bookingTime)

        var time_slot : number = data?.message?.data?.time_slot || -1;

        //let st_h = Math.floor( time_slot / 60);
        //let st_m = (time_slot - st_h * 60).toString();
        //if (st_m.length == 1) st_m = `0${st_m}`

        //if ()


        //serviceData.list_of_services?.map( (row_srv)
        //console.log(data?.message)
        console.log(new Date(data?.message?.data?.date))
        setDoneBookingData({
            employee: empl_name,                        // +
            tasks: task_labels,                  //+
            duration: duration,                         //+
            date: DateFormatter.format(new Date(data?.message?.data?.date)),  //+
            startTime: utils.timeSlot2str(time_slot), //+
        })

        setIsLoading(false);



    }


    const DateFormatter = new Intl.DateTimeFormat('uk', {
        weekday: "long",
        day: "numeric",
        month: "long",
    });


    const timeSlotColor = ( key: number) => {
        var color : ColorTypes = "success"


        if (key == selectedTimeSlot)
            color = "warning"

        if ( key > selectedTimeSlot && key < selectedTimeSlot + bookingTime.total_time_min)
            color = "primary" //secondary error info primary

        return color;
        //(Number(key) >= selectedTimeSlot && Number(key) < selectedTimeSlot + bookingTime.total_time_min) ? "warning" : "success"

    }

    const formatUTC = (dateInt : Date | undefined, addOffset = false) => {
        let date = (!dateInt) ? new Date : new Date(dateInt);
        if (typeof dateInt === "string") {
            return date;
        } else {
            const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
            const offsetDate = new Date();
            offsetDate.setTime(date.getTime() + offset * 60000)
            return offsetDate;
        }
    }

    useEffect( () => {
        setFooter(null);
        setIsLoading(true);
        registerLocale("uk", uk);

        const domain : string = (url_name || "").toString()
        const fetchData = async () => {
            let data  = await API.service_check_domain(domain);
            if (data.message.error > 0) {
                console.log(data.message)
                setServiceData(null);
                return;
            } else {
                console.log(data.message)
                setServiceData(data.message.data);
            }
            setIsLoading(false);
            //const sdata : IService = data.message.data;
            //generateTimeSlots(data.message.data)
            //checkTasksAvailability();
            //console.log(sdata.startDate)

        }
        fetchData();
    }, [])


    useEffect( () => {

        //if (dateCalendar)
        //console.log(dateCalendar.getTimezoneOffset())
        //console.log(dateCalendar.getTimezoneOffset())

        setIsLoading(true);
        //console.log(inputEmployee)
        console.log('----refresh booking-----')

        // let nowDate : string = ((dateCalendar?.getMonth() || 0 ) +  1) + '-' + dateCalendar?.getDate() + '-'+ dateCalendar?.getFullYear();
        // console.log(dateCalendar)
        // console.log('Day: ', dateCalendar?.getDate())
        // console.log('Month: ', (dateCalendar?.getMonth() || 0) +1)
        // console.log('Year: ', dateCalendar?.getFullYear())
        // console.log(nowDate)
        // console.log(new Date(nowDate))


        const utcDate = new Date(Date.UTC(
            dateCalendar?.getFullYear() || 0,  // Year
            (dateCalendar?.getMonth() || 0 ),  // Month (0-based, so subtract 1)
            dateCalendar?.getDate(),  // Day
            0, 0, 0, 0  // Hours, minutes, seconds, milliseconds (set to 0 for midnight)
        ));

       // console.log(utcDate)

        //console.log(serviceData)
        if (serviceData?._id == undefined) return;
        //console.log(serviceData)
        const fetchData = async () => {
            let token = `${userInfo.authToken};;${userInfo.authDate}`;
            let data  = await API.booking_getBookingInfo(
                utils.clearSymbol(serviceData?._id || ""),
                utils.clearSymbol(inputEmployee),
                inputTasks,
                utcDate,
                token
            );

            processBookingInfo(data.message.data)
            setIsLoading(false);

        }

        fetchData();

        //new Date( utils.dateTime2Date(dateCalendar))

    }, [serviceData, inputEmployee, inputTasks, dateCalendar, refreshBookings])


    const getServiceType = ( service_type : string) => {
        for (var st of CONFIG.SERVICE_TYPES) {
            if (st.value == service_type)    return st
        }
        return {label: "", value : ""};

    }



    return (
        <>

            <div className={doneBookingData != null ? Styles.BookingDone : Styles.hide}>
                <div className={Styles.BookingDone_Window}>
                    <center>
                        <h2 className={Styles.title}>Вітаємо!</h2>
                        <CheckCircleIcon color="success" fontSize="inherit" className={Styles.doneIco}/>
                        <span className={Styles.title}>Запис зареєстровано</span>
                    </center>

                    <div className={Styles.block}>


                        <Grid container spacing={0.2} >
                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Дата:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{doneBookingData ? doneBookingData.date :'' }</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Початок:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{doneBookingData ? doneBookingData.startTime :'' }</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Тривалість:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{doneBookingData ? doneBookingData.duration :'' }</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Працівник:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{ doneBookingData ? doneBookingData.employee : '' }</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Послуги:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{ doneBookingData ? doneBookingData.tasks.join(', ') : ''}</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>&nbsp;</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>&nbsp;</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Місто:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{ (CONFIG.CITIES.find( ( row) => row.value === serviceData?.city  ))?.label}</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>Адреса:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{serviceData?.address}</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>тел.:</Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#F6C445'}}>{utils.formatPhoneNumber(serviceData?.phone || "") }</Grid>

                            <Grid  xs={4} sx={{textAlign: 'right', paddingRight: '10px'}}>карта: </Grid>
                            <Grid  xs={8} sx={{textAlign: 'left', color:'#A0E548'}}><a href={`https://www.google.com/maps/search/?api=1&query=${serviceData?.map?.lat},${serviceData?.map?.lng}`} target={"_blank"}>Відкрити карту</a></Grid>
                        </Grid>

                    </div>


                    <Grid container spacing={0.2} >
                        <Grid  xs={6} sx={{textAlign: 'right', paddingRight: '10px'}}>
                            <Button onClick={handleBackHome} color={"info"}  size="medium" startIcon={<AppsOutlinedIcon />}>
                                <span className={Styles.btnText}>На головну</span>
                            </Button>
                        </Grid>
                        <Grid  xs={6} sx={{textAlign: 'left'}}>
                            <Button onClick={handleBookMore} color={"success"} size="medium" endIcon={<ArrowForwardIcon />}>
                                <span className={Styles.btnText}>Записати Ще</span>
                            </Button>
                        </Grid>


                    </Grid>

                </div>
            </div>

            {serviceData == null ?
                <div>
                    Сервіс <strong>{url_name}</strong> не знайдено, перевірте URL адресу.
                </div>
                :
                <div>




                    <Grid container spacing={0} >
                        <Grid  xs={6}  sx={{textAlign:"left", color:'#E45F2B', fontSize: '12px'}}>
                            {getServiceType(serviceData.service_type || "").label}
                        </Grid>
                        <Grid  xs={6} sx={{textAlign:"right", color:'#F6C445', fontSize: '12px'}}>
                            {url_name}.zapys.vip
                        </Grid>
                    </Grid>
                    <div style={{ paddingBottom: '5px' }}></div>
                    <h2>{serviceData.name}</h2>

                    { userAuth == true ? <div> </div>
                        :<>
                        <Login
                            userAuth={userAuth}
                            setUserAuth={setUserAuth}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            setGlobalMessage={setGlobalMessage}
                        />
                            <div style={{ paddingBottom: '15px' }}></div>
                        </>
                    }
                    <div style={{ paddingBottom: '8px' }}></div>

                    <div className={Styles.input_group}>

                        <FormControl fullWidth variant="outlined"  sx={{ m: 0, minWidth: 120 }} size="small" >
                            <InputLabel id="select-employee">Працівник</InputLabel>

                            <Select
                                style={{borderRadius: '5px 5px 5px 5px', textAlign: "right", color: '#F6C445'}}
                                labelId="demo-simple-select-label"
                                id="booking_employee"
                                label="Працівник2"
                                variant="outlined"
                                value={inputEmployee}
                                onChange={(e)=>setInputEmployee(e.target.value) }
                                //error={errorValid2.length > 0}
                                //onBlur={()=>ValidateInput('city')}
                            >


                                // phone: string,
                                // nickname: string,
                                // info: string,
                                // allow_others_tasks: boolean,
                                // enabled: boolean,
                                // competences: string[],      //array of IListedService.id's
                                // work_shifts: IWorkingSlot[],


                                <MenuItem
                                    key={"any"}
                                    value={"any"}

                                >
                                    <ListItemText primary={"По доступності"} style={{textAlign:"left"}}/>
                                    <ListItemText primary={"виберемо працівника для Вас"} style={{textAlign:"right", color: '#7a7a7a'}}/>

                                </MenuItem>

                                {serviceData.employees?.map((row) => (
                                    <MenuItem
                                        key={row.phone}
                                        value={row.phone}
                                        disabled={ !availableEmployees[row.phone] }
                                    >

                                        <ListItemText primary={row.nickname || "Працівник"} style={{textAlign:"left"}} />
                                        <ListItemText primary={row.info} style={{textAlign:"right", color: '#7a7a7a'}} />

                                    </MenuItem>

                                ))}

                            </Select>
                        </FormControl>
                    </div>

                    <div className={Styles.Panel_title}>Послуги</div>
                    <div className={Styles.block} >


                        { serviceData.list_of_services?.map( (row_srv) => (

                            // <FormControlLabel
                            //     control={
                            //         <Checkbox checked={slot.wdays["7"]} onChange={ (v) => timeSlot_UpdCB(indexe, v, "wdays")}  color="secondary" name="7" size={"small"} style={{paddingRight:'0px', paddingLeft:'1px'}}/>
                            //     }
                            //     label="нд"
                            // />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={row_srv.sid}
                                        //checked={ inputTasks[row_srv.sid] }
                                        checked={inputTasks[row_srv.sid] || false}
                                        onChange={ (v) =>  setInputCBTasks(v.target.checked, row_srv.sid)}
                                        color={"success"}
                                        name={row_srv.sid}
                                        size={"small"}
                                        style={{paddingRight:'2px', paddingLeft:'5px', paddingTop: '0px', paddingBottom: '0px'}}
                                        disabled={ !availableTasks[row_srv.sid]}
                                    />
                                }
                                label={row_srv.name + ` (${utils.iTime2str(row_srv.time)})`}
                                key={row_srv.sid}
                                style={{display:'block'}}
                            />
                        ))}



                    </div>

                    <div style={{textAlign: 'center', color: (bookingTime.count > 0? '#29AC16': '#E45F2B') }}>
                        Вибрано: {bookingTime.count} послуг(и) ( разом {utils.iTime2str(bookingTime.total_time)})
                    </div>
                    <div style={{ paddingBottom: '4px' }}></div>
                    <center>
                        <Button
                            color="success"
                            variant={"contained"}
                            disabled={ (selectedTimeSlot == -1 || dateCalendar == null) ? true : false}
                            fullWidth
                            onClick={btnBookSlot}
                            size={"large"}
                        >
                            Записатися

                            { dateCalendar == undefined && selectedTimeSlot!=-1  ?
                                <sub style={{color: '#E45F2B', textTransform: 'lowercase'}}>
                                    &nbsp;&nbsp;&nbsp;(Виберіть дату)
                                </sub>
                                :
                                <></>
                            }
                        </Button>
                    </center>
                    <div style={{ paddingBottom: '10px' }}></div>

                    <Grid container spacing={0} >
                        <Grid  xs={8.5} md={7}>
                            <div>
                                <div>
                                    <DatePicker
                                        inline
                                        showIcon
                                        selected={dateCalendar}
                                        // excludeDates={[new Date(), ...]}
                                        // excludeDateIntervals={[{ start: new Date(), end: new Date() },...]}
                                        // includeDates={[new Date()]}
                                         includeDateIntervals={ calendarIntervals}
                                        // holidays={[  { date: "2024-01-01", holidayName: "Новий рік" } ] }

                                        //minDate={ new Date() >= (new Date(serviceData.startDate || "") || new Date()) ? new Date() : new Date(serviceData.startDate || "")}
                                        //minDate={ new Date(1000* (serviceData.startDate || 0)) > new Date() ? new Date(1000* (serviceData.startDate || 0)): new Date()}
                                        //maxDate={ new Date(1000* (serviceData.endDate || 0)) >  new Date() ? new Date(1000* (serviceData.endDate || 0)) : null}
                                        locale="uk"
                                        dateFormat="MMMM d, yyyy HH:mm"
                                        //todayButton="Сьогодні"
                                        //onCalendarClose={handleCalendarClose}
                                        //includeDateIntervals={[  { start: new Date("2023-10-15"), end: new Date("2023-11-10") } ]}


                                        //showTimeSelect
                                        //timeFormat="HH:mm"
                                        //timeIntervals={30}
                                        //timeCaption="Час"
                                        //minTime={new Date("2023-10-25T10:00:00")}
                                        //maxTime={new Date("2023-10-25T18:00:00")}
                                        // minTime={setHours(setMinutes(new Date(), 0), 17)}
                                        // maxTime={setHours(setMinutes(new Date(), 30), 20)}
                                        onChange={(date) => {setDateCalendar(date || undefined)}}
                                        onMonthChange={ (date ) => {setDateCalendar(date || undefined)}}
                                        //onSelect={( date ) => {handleCalendarClick(date || undefined)} }
                                        //onDayMouseEnter= { ( date ) => { console.log('-0-0-0-0-', date)} }

                                    />

                                </div>


                            </div>
                        </Grid>
                        <Grid  xs={3.5}  md={5}>
                           <>
                               <div style={{textAlign:"right"}}>
                                   {dateCalendar? DateFormatter.format(dateCalendar) : ""}
                               </div>
                               <div style={{display:"block", height: "300px", overflow: "auto"}}>
                                   { Object.entries(timeSlotsList).map( ( [key, val] ) => {
                                       return (
                                           <Button
                                               key={key}
                                               color={ timeSlotColor(Number(key)) }
                                               onClick={()=>timeSlotClick(key)}
                                               fullWidth
                                               variant={ Number(key) == selectedTimeSlot ? "contained" : "text"}
                                           >
                                               <span style={{fontSize: ( Number(key) == selectedTimeSlot ? "11px" : "14px" )}}>
                                               {val}
                                               {
                                                   Number(key) == selectedTimeSlot ? <>- {utils.timeSlot2str(Number(key) + bookingTime.total_time_min)}</> : <></>
                                                }
                                                </span>
                                           </Button>);

                                   })}
                               </div>

                           </>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    );
}
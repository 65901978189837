import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";

import {SoonPage} from "./soon/Soon";
import {Login} from "../components/Login/Login";
import {Logout} from "../components/Login/Logout";
import {Footer} from "../components/footer/Footer";
import {Service_Edit} from "../components/service_edit/Service_Edit";

import {IUserInfo} from "../models/user";
import {Link} from "react-router-dom";
import footer_my_calendar from "../images/ui/footer_my_calendar.png";
import footer_my_service from "../images/ui/footer_my_service.png";
import footer_my_profile from "../images/ui/footer_my_profile.png";
import footer_my_clients from "../images/ui/footer_my_clients.png";
import {AlertColor} from "@mui/material";
import {Service_List} from "../components/service_list/Service_List";
import AddHomeIcon from "@mui/icons-material/AddHome";
import Button from "@mui/material/Button";
import Styles from "../components/service_list/Service_list.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {FooterLinks_MainMenu} from "../components/footer/FooterLinks_MainMenu";

interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    footer: React.ReactNode;
    setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    //authFrame: Window | null;
    //handleAdd: (e : React.FormEvent) => void;
}
export const UserServicesPage = ({userAuth, setUserAuth, userInfo, setUserInfo, footer, setFooter, setGlobalMessage, setIsLoading} : Props) => {



    useEffect( () => {
        setFooter(FooterLinks_MainMenu(["home", "bookings", "profile", "clients"]))
    }, [])
    /*
    useEffect(() => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        console.log('Subdomain: ' + subdomain);

    }, []);
     */


    return (
        <div>
            {/*<SoonPage/>*/}

            { userAuth ?
                <>

                    <Service_List
                        userAuth={userAuth}
                        setUserAuth={setUserAuth}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        footer={footer}
                        setFooter={setFooter}
                        setGlobalMessage={setGlobalMessage}
                        setIsLoading={setIsLoading}
                    />

                    {/* Add service Btn*/}
                    <Button component={Link} to="/services/add" variant="contained" style={{backgroundColor: '#A0E548', color: 'black', marginTop: '20px'}}  size="large" >
                        <Grid container spacing={0}>
                            <Grid xs={12}  spacing={0}>
                                <span style={{ fontSize: '20px', lineHeight: '20px', display: 'block', width: '100%', color: '#24263A', textAlign: 'center' }}>Зареєструвати Послугу</span>
                            </Grid>
                            <Grid  xs={12}  spacing={0}>
                                <span style={{fontSize: '11px', lineHeight: '11px', display: 'block', width: '100%', color: '#E45F2B', textAlign: 'center'}}>Для тих хто працює у сфері надання послуг</span>
                            </Grid>
                        </Grid>
                    </Button>
                </>

                :
                <Login
                    userAuth={userAuth}
                    setUserAuth={setUserAuth}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    setGlobalMessage={setGlobalMessage}

                />
            }
        </div>
    );
};

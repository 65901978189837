import React from "react";
import {IUserInfo} from "../../models/user";
import {Alert, AlertColor, Snackbar} from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';


interface Props{
    globalMessage: {type: AlertColor, message: String};
    setGlobalMessage:  React.Dispatch<React.SetStateAction<{type: AlertColor, message: String}>>;
}

// message types: success, error, warning, info
export const Notification = ({globalMessage, setGlobalMessage} : Props) => {


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setGlobalMessage({type: globalMessage.type, message: ""});
    };

    return (
        <Snackbar
            open={globalMessage.message.length > 0}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ 'vertical': 'top', 'horizontal':'center'}}
        >
            <Alert
                onClose={handleClose}
                severity={globalMessage.type}
                sx={{ width: '100%' }}
                variant="filled"
            >
                {globalMessage.message}

            </Alert>
        </Snackbar>
    );
};

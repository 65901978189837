import React from "react";
import {IUserInfo} from "../models/user";

interface Props{
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo,
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>
    //handleAdd: (e : React.FormEvent) => void;
}

const DoLogout = ( {setUserAuth, userInfo, setUserInfo } : Props) => {
    setUserAuth(false)
    const nowD = Date.now();
    setUserInfo( {authDate : nowD, authToken: "", phone: userInfo.phone, name: "", city: "", info: "" } );
    // TODO : replace to iframe localstorage
    //localStorage.setItem('authToken', "")
    //localStorage.setItem('authDate', nowD.toString());
}


export default {DoLogout}